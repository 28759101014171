/* @import url(//db.onlinewebfonts.com/c/90c8de006caf7a6d22c680771a2305a2?family=Swis721+Cn+BT); */
/* @font-face {
  font-family: swiss721;
  src: url("../../assets/fonts/Swis721\ Cn\ BT\ Bold.ttf");
} */

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-SemiBold.ttf");
}
/* @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap"); */
/* @font-face {
  font-family: "digital";
  src: url("%PUBLIC_URL%/digital-7\ \(mono\ italic\).ttf"),
} */
.russianpokerlandscape {
  transform-origin: left top;
  transform: scale(0.288);
  margin-left: 31.6px;
  margin-top: 0.979px;
}



:root {
  --clr_ff: #fff;
  --clr_00: #000;
  --clr_1: #9a6503;
  --clr_3a: #3a3a3a;
  --clr_cd: #cdd6d9;
  --clr_1a: #1a2730;
  --clr_11: #24a141;
  --clr_12: #f40037;
}
.clr_ff {color: var(--clr_ff);}
.clr_00 {color: var(--clr_00);}
.clr_1 {color: var(--clr_1);}
.clr_3a {color: var(--clr_3a);}
.clr_cd {color: var(--clr_cd);}
.bg_00 {background: var(--clr_00);}
.bg_1 {background: var(--clr_1);}
.bg_1a {background: var(--clr_1a);}
.bg_ff {background: var(--clr_ff);}
body {
    display: block;
    margin: 0;
    /* overflow: hidden; */
    font-family: "Poppins" !important;
    font-size: 14px;

     -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
}

.appMain {
    width: 100%;
    height: 100%; 
    /* overflow: hidden; */
}

.appMainBg {
    /* background-image: url("../../assets/games/fgaGameBG.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    background-color: #8f6842;
}



.fd {
    width: 100%;
    float: left;
    position: relative;
}

* {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

/*********** margin start ************/

.m_auto {
    margin: auto;
}

.m_0 {
    margin: 0px;
}

.m_1 {
    margin: 1px;
}

.m_2 {
    margin: 2px;
}

.m_3 {
    margin: 3px;
}

.m_4 {
    margin: 4px;
}

.m_5 {
    margin: 5px;
}

.m_10 {
    margin: 10px;
}

.m_15 {
    margin: 15px;
}

.m_20 {
    margin: 20px;
}

.m_25 {
    margin: 25px;
}

.m_30 {
    margin: 30px;
}

.m_35 {
    margin: 35px;
}

.m_40 {
    margin: 40px;
}

.m_45 {
    margin: 45px;
}

.m_50 {
    margin: 50px;
}

.m_t_0 {
    margin-top: 0px
}

.m_t_2 {
    margin-top: 2px
}

.m_t_5 {
  margin-top: 5px;
  z-index: 2;
}
.m_t_10 {
    margin-top: 10px
}

.m_t_15 {
    margin-top: 15px
}

.m_t_20 {
    margin-top: 20px
}

.m_t_25 {
    margin-top: 25px
}

.m_t_30 {
    margin-top: 30px
}

.m_t_35 {
    margin-top: 35px
}

.m_t_40 {
    margin-top: 40px
}

.m_t_45 {
    margin-top: 45px
}

.m_t_50 {
    margin-top: 50px
}

.m_t_55 {
    margin-top: 55px
}

.m_t_60 {
    margin-top: 60px
}
.m_t_70 {
    margin-top: 70px
}
.m_t_80 {
    margin-top: 80px
}
.m_t_90 {
    margin-top: 90px
}
.m_t_100 {
    margin-top: 100px
}

.m_b_0 {
    margin-bottom: 0px;
}

.m_b_5 {
    margin-bottom: 5px;
}

.m_b_10 {
    margin-bottom: 10px;
}

.m_b_15 {
    margin-bottom: 15px;
}

.m_b_20 {
    margin-bottom: 20px;
}

.m_b_25 {
    margin-bottom: 25px;
}

.m_b_30 {
    margin-bottom: 30px;
}

.m_b_35 {
    margin-bottom: 35px;
}

.m_b_40 {
    margin-bottom: 40px;
}

.m_b_45 {
    margin-bottom: 45px;
}

.m_b_50 {
    margin-bottom: 50px;
}

.m_b_55 {
    margin-bottom: 55px;
}

.m_b_60 {
    margin-bottom: 60px;
}

.m_t-7 {
    margin-top: -7px
}

.m_t-15 {
    margin-top: -15px
}
.m_t-20 {
    margin-top: -20px
}
.m_t-30 {
    margin-top: -30px
}
.m_t-80 {
    margin-top: -80px
}

.m_tb_0 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.m_tb_5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.m_tb_10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.m_tb_15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.m_tb_20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.m_tb_25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.m_tb_30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.m_tb_35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.m_tb_40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.m_tb_45 {
    margin-top: 45px;
    margin-bottom: 45px;
}

.m_tb_50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.m_tb_55 {
    margin-top: 55px;
    margin-bottom: 55px;
}

.m_tb_60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.m_l_0 {
    margin-left: 0px;
}

.m_l_5 {
    margin-left: 5px;
}

.m_l_10 {
    margin-left: 10px;
}

.m_l_15 {
    margin-left: 15px;
}

.m_l_20 {
    margin-left: 20px;
}

.m_l_25 {
    margin-left: 25px;
}

.m_l_30 {
    margin-left: 30px;
}

.m_l_35 {
    margin-left: 35px;
}

.m_l_40 {
    margin-left: 40px;
}

.m_l_45 {
    margin-left: 45px;
}

.m_l_50 {
    margin-left: 50px;
}

.m_l_55 {
    margin-left: 55px;
}

.m_l_60 {
    margin-left: 60px;
}

.m_r_0 {
    margin-right: 0px;
}

.m_r_5 {
    margin-right: 5px;
}

.m_r_10 {
    margin-right: 10px;
}

.m_r_15 {
    margin-right: 15px;
}

.m_r_20 {
    margin-right: 20px;
}

.m_r_25 {
    margin-right: 25px;
}

.m_r_30 {
    margin-right: 30px;
}

.m_r_35 {
    margin-right: 35px;
}

.m_r_40 {
    margin-right: 40px;
}

.m_r_45 {
    margin-right: 45px;
}

.m_r_50 {
    margin-right: 50px;
}

.m_r_55 {
    margin-right: 55px;
}

.m_r_60 {
    margin-right: 60px;
}

.m_lr_0 {
    margin-left: 0px;
    margin-right: 0px;
}

.m_lr_5 {
    margin-left: 5px;
    margin-right: 5px;
}

.m_lr_10 {
    margin-left: 10px;
    margin-right: 10px;
}

.m_lr_15 {
    margin-left: 15px;
    margin-right: 15px;
}

.m_lr_20 {
    margin-left: 20px;
    margin-right: 20px;
}

.m_lr_25 {
    margin-left: 25px;
    margin-right: 25px;
}

.m_lr_30 {
    margin-left: 30px;
    margin-right: 30px;
}

.m_lr_35 {
    margin-left: 35px;
    margin-right: 35px;
}

.m_lr_40 {
    margin-left: 40px;
    margin-right: 40px;
}

.m_lr_45 {
    margin-left: 45px;
    margin-right: 45px;
}

.m_lr_50 {
    margin-left: 50px;
    margin-right: 50px;
}

.m_lr_55 {
    margin-left: 55px;
    margin-right: 55px;
}

.m_lr_60 {
    margin-left: 60px;
    margin-right: 60px;
}

.row.m_lr_0 {
    margin-left: 0px;
    margin-right: 0px;
}

.m_lr-15 {
    margin-left: -15px;
    margin-right: -15px;
}

.m_r-15 {
    margin-right: -15px;
}

.m_l-15 {
    margin-left: -15px;
}

/*********** margin end ************/

/************* padding start ***************/

.p_0 {
    padding: 0px;
}

.p_5 {
    padding: 5px;
}

.p_8 {
    padding: 8px;
}

.p_9 {
    padding: 9px;
}

.p_10 {
    padding: 10px;
}

.p_12 {
    padding: 12px;
}

.p_15 {
    padding: 15px;
}

.p_20 {
    padding: 20px;
}

.p_25 {
    padding: 25px;
}

.p_30 {
    padding: 30px;
}

.p_35 {
    padding: 35px;
}

.p_40 {
    padding: 30px;
}

.p_45 {
    padding: 35px;
}

.p_50 {
    padding: 50px;
}

.p_55 {
    padding: 55px;
}

.p_60 {
    padding: 60px;
}

.p_80 {
    padding: 80px;
}

.p_100 {
    padding: 100px;
}

.p_120 {
    padding: 120px;
}

.p_150 {
    padding: 150px;
}

.p_t_0 {
    padding-top: 0px;
}

.p_t_5 {
    padding-top: 5px;
}

.p_t_8 {
    padding-top: 8px;
}

.p_t_9 {
    padding-top: 9px;
}

.p_t_10 {
    padding-top: 10px;
}

.p_t_15 {
    padding-top: 15px;
}

.p_t_20 {
    padding-top: 20px;
}

.p_t_25 {
    padding-top: 25px;
}

.p_t_30 {
    padding-top: 30px;
}

.p_t_35 {
    padding-top: 35px;
}

.p_t_40 {
    padding-top: 40px;
}

.p_t_45 {
    padding-top: 45px;
}

.p_t_50 {
    padding-top: 50px;
}

.p_t_55 {
    padding-top: 55px;
}

.p_t_60 {
    padding-top: 60px;
}

.p_t_80 {
    padding-top: 80px;
}

.p_t_100 {
    padding-top: 100px;
}

.p_t_120 {
    padding-top: 120px;
}

.p_t_150 {
    padding-top: 150px;
}

.p_t_200 {
    padding-top: 200px;
}

.p_b_0 {
    padding-bottom: 0px;
}

.p_b_5 {
    padding-bottom: 5px;
}

.p_b_8 {
    padding-bottom: 8px;
}

.p_b_9 {
    padding-bottom: 9px;
}

.p_b_10 {
    padding-bottom: 10px;
}

.p_b_15 {
    padding-bottom: 15px;
}

.p_b_20 {
    padding-bottom: 20px;
}

.p_b_25 {
    padding-bottom: 25px;
}

.p_b_30 {
    padding-bottom: 30px;
}

.p_b_35 {
    padding-bottom: 35px;
}

.p_b_40 {
    padding-bottom: 30px;
}

.p_b_45 {
    padding-bottom: 35px;
}

.p_b_50 {
    padding-bottom: 50px;
}

.p_b_55 {
    padding-bottom: 55px;
}

.p_b_60 {
    padding-bottom: 60px;
}

.p_b_80 {
    padding-bottom: 80px;
}

.p_b_100 {
    padding-bottom: 100px;
}

.p_b_120 {
    padding-bottom: 120px;
}

.p_b_150 {
    padding-bottom: 150px;
}

.p_tb_0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.p_tb_5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.p_tb_8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.p_tb_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.p_tb_15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p_tb_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.p_tb_25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.p_tb_30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.p_tb_35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.p_tb_40 {
    padding-top: 30px;
    padding-bottom: 40px;
}

.p_tb_45 {
    padding-top: 35px;
    padding-bottom: 45px;
}

.p_tb_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.p_tb_55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.p_tb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.p_tb_80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.p_tb_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.p_tb_120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.p_tb_150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.p_l_0 {
    padding-left: 0px;
}

.p_l_5 {
    padding-left: 5px;
}

.p_l_8 {
    padding-left: 8px;
}

.p_l_9 {
    padding-left: 9px;
}

.p_l_10 {
    padding-left: 10px;
}

.p_l_15 {
    padding-left: 15px;
}

.p_l_20 {
    padding-left: 20px;
}

.p_l_25 {
    padding-left: 25px;
}

.p_l_30 {
    padding-left: 30px;
}

.p_l_35 {
    padding-left: 35px;
}

.p_l_40 {
    padding-left: 30px;
}

.p_l_45 {
    padding-left: 35px;
}

.p_l_50 {
    padding-left: 50px;
}

.p_l_55 {
    padding-left: 55px;
}

.p_l_60 {
    padding-left: 60px;
}

.p_l_80 {
    padding-left: 80px;
}

.p_l_100 {
    padding-left: 100px;
}

.p_l_120 {
    padding-left: 120px;
}

.p_l_150 {
    padding-left: 150px;
}

.p_r_0 {
    padding-right: 0px;
}

.p_r_5 {
    padding-right: 5px;
}

.p_r_8 {
    padding-right: 8px;
}

.p_r_9 {
    padding-right: 9px;
}

.p_r_10 {
    padding-right: 10px;
}

.p_r_15 {
    padding-right: 15px;
}

.p_r_20 {
    padding-right: 20px;
}

.p_r_25 {
    padding-right: 25px;
}

.p_r_30 {
    padding-right: 30px;
}

.p_r_35 {
    padding-right: 35px;
}

.p_r_40 {
    padding-right: 30px;
}

.p_r_45 {
    padding-right: 35px;
}

.p_r_50 {
    padding-right: 50px;
}

.p_r_55 {
    padding-right: 55px;
}

.p_r_60 {
    padding-right: 60px;
}

.p_r_80 {
    padding-right: 80px;
}

.p_r_100 {
    padding-right: 100px;
}

.p_r_120 {
    padding-right: 120px;
}

.p_r_150 {
    padding-right: 150px;
}

.p_r_200 {
    padding-right: 200px;
}

.p_lr_0 {
    padding-left: 0px;
    padding-right: 0px;
}

.p_lr_5 {
    padding-left: 5px;
    padding-right: 5px;
}

.p_lr_10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p_lr_15 {
    padding-left: 15px;
    padding-right: 15px;
}

.p_lr_20 {
    padding-left: 20px;
    padding-right: 20px;
}

.p_lr_25 {
    padding-left: 25px;
    padding-right: 25px;
}

.p_lr_30 {
    padding-left: 30px;
    padding-right: 30px;
}

.p_lr_35 {
    padding-left: 35px;
    padding-right: 35px;
}

.p_lr_40 {
    padding-left: 30px;
    padding-right: 40px;
}

.p_lr_45 {
    padding-left: 35px;
    padding-right: 45px;
}

.p_lr_50 {
    padding-left: 50px;
    padding-right: 50px;
}

.p_lr_55 {
    padding-left: 55px;
    padding-right: 55px;
}

.p_lr_60 {
    padding-left: 60px;
    padding-right: 60px;
}

.p_lr_80 {
    padding-left: 80px;
    padding-right: 80px;
}

.p_lr_85 {
    padding-left: 85px;
    padding-right: 85px;
}

.p_lr_100 {
    padding-left: 100px;
    padding-right: 100px;
}

.p_lr_120 {
    padding-left: 120px;
    padding-right: 120px;
}

.p_lr_150 {
    padding-left: 150px;
    padding-right: 150px;
}

/************* padding end ***************/

/************* font start ****************/

.font_5 {
    font-size: 5px;
}

.font_6 {
    font-size: 6px;
}

.font_7 {
    font-size: 7px;
}

.font_8 {
    font-size: 8px;
}

.font_9 {
    font-size: 9px;
}

.font_10 {
    font-size: 10px;
}

.font_11 {
    font-size: 11px;
}

.font_12 {
    font-size: 12px;
}

.font_13 {
    font-size: 13px;
}

.font_14 {
    font-size: 14px;
}

.font_15 {
    font-size: 15px;
}

.font_16 {
    font-size: 16px;
}

.font_17 {
    font-size: 17px;
}

.font_18 {
    font-size: 18px;
}

.font_19 {
    font-size: 19px;
}

.font_20 {
    font-size: 20px;
}

.font_21 {
    font-size: 21px;
}

.font_22 {
    font-size: 22px;
}

.font_25 {
    font-size: 25px;
}
.font_28 {
    font-size: 28px;
}

.font_30 {
    font-size: 30px;
}

.font_35 {
    font-size: 35px;
}

.font_40 {
    font-size: 40px;
}

.font_50 {
    font-size: 50px;
}

.font_w_100 {
    font-weight: 100;
}

.font_w_200 {
    font-weight: 200;
}

.font_w_300 {
    font-weight: 300;
}

.font_w_400 {
    font-weight: 400;
}

.font_w_500 {
    font-weight: 500;
}

.font_w_600 {
    font-weight: 600;
}

.font_w_700 {
    font-weight: 700;
}

.font_w_800 {
    font-weight: 800;
}

.bold {
    font-weight: bold;
}

/************* font end ****************/

/************ line height start ***************/

.lh_12 {
    line-height: 12px;
}
.lh_13 {
    line-height: 13px;
}

.lh_17 {
    line-height: 17px;
}

.lh_30 {
    line-height: 30px;
}

.lh_25 {
    line-height: 25px;
}

.lh_35 {
    line-height: 35px;
}

.lh_45 {
    line-height: 45px;
}

/************ line height end ***************/

.rds_1 {
    border-radius: 1px;
}

.rds_2 {
    border-radius: 2px;
}

.rds_3 {
    border-radius: 3px;
}

.rds_4 {
    border-radius: 4px;
}

.rds_5 {
    border-radius: 5px;
}

.rds_10 {
    border-radius: 10px;
}

.rds_15 {
    border-radius: 15px;
}

.rds_50 {
    border-radius: 50px;
}

.v_align {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.center_content {
    display: block;
    align-items: center;
    flex-direction: row;
}

.shadow_1 {
    box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
}

.shadow_2 {
    box-shadow: 0px 0px 5px 2px rgba(12, 0, 46, 0.1);
}

.pointer {
    cursor: pointer;
}

.clr_ff {
    color: var(--clr_ff);
}



/**************************** bootstrap css start ********************************************/


@media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) { 
    .container {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  @media (max-width: 992px) {
    .LogoLogBox{
      width: 150px;
    }
  }
  
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px;
    }
  }
  
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
   
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 7px;
    padding-left: 7px;
  }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
   
  
  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  
  .col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  
  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  
  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  
  .col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  
  .col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  } 
  
  @media (min-width: 576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
     
    .col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-sm-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sm-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sm-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sm-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
      
  }
  
  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
     
    .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
     
  }
  
  @media (min-width: 992px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
     
    .col-lg-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-lg-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-lg-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-lg-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-lg-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
     
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
     
    .col-xl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
     
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link { 
    background-color: #007bff;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link { 
    background-color: #007bff;
  }  
  .nav-tabs .nav-link.active  {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
} 
.fade.show {
  opacity: 1;
}
.fade {
  opacity: 0;
  transition: opacity .15s linear;
}


.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

/**************************** bootstrap css end ********************************************/
.maxSize_1140 {max-width: 1140px;margin: auto;padding-left: 5px; padding-right: 5px;}




.scor_list ul li:nth-child(even) { background: #070a0e; color: #f00; }
.scor_list ul li:nth-child(odd) { color: #fff; }
.scor_list ul li {
    list-style: none;
    text-align: center; 
    font-size: 21px;
    padding: 5px;
    font-family: digital;
}
.scor_list {
    background: #18222e63;
    padding: 15px;
    margin: 15px; 
}
 
.ar_bt_1.active {
  box-shadow: 0px 0px 23px 6px rgb(255 82 0);
  animation: btnActive 0.5s infinite; 
}
@keyframes btnActive {
  0% { box-shadow: 0 0 15px rgb(255, 238, 0); background: rgb(221, 118, 1); }
  50% { box-shadow: 0 0 0px; background: red; }
  100% { box-shadow: 0 0 15px rgb(255, 238, 0); background:  rgb(221, 118, 1);}
} 
button {cursor: pointer;}
.text_center {text-align: center;}
.timer_Sec {
  background: radial-gradient(red, #ff8100);
  width: 125px;
  height: 125px;
  border-radius: 50%; 
  text-align: center; 
  font-size: 55px;
  font-weight: bold;
  color: #fff;
  border: solid 4px #d60000dd; 
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* right: 10px; */
  right: 150px;
  float: right;
  }
  .timer_Sec.timerinactive {background: radial-gradient(#565656, #757575); border: solid 4px #949494dd; }
  
@media only screen and (max-width: 800px) {
  .scor_list ul li { font-size: 14px;width: 50%;float: left;margin-bottom: 5px;} 
  .scor_list {padding: 5px;margin: 5px;} 
}

/*button.fd.ar_bt_1.inactive {*/
button.fd.ar_bt_1:disabled {
    /* background: linear-gradient(#301b1b, #484848, #301b1b); */
    background: linear-gradient(#992121, #5f1010, #992121);
    cursor: no-drop;
}
.popupWin {
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    background: #000;
}
.popupWin.block{
    display:block
 }
 .popupWin.none{
    display:none
 }
.popupWin .pop_text {
    max-width: 30%;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 30px;
    /* font-size: 28px; */
    height: 20%;
}
.popupWin .session_closed {
  width: 1000px;
  background: #fff;
  border-radius: 20px;
  /* border-radius: 10px; */
  text-align: center;
  padding: 20px;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 50px;
  /* font-size: 28px; */
  height: 500px;
  /* height: 37%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px 0px #b8730f;
  height: -moz-fit-content;
  border: 3px solid #b8730f;
  height: fit-content;
  /* width: fit-content; */
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
}
.errorPopup_session_closed{
  width: 20%;
}
.contFlex_80 {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.session_closed_text_container{
  text-align: left;
    color: #be9100;
    font-size: 35px;
    text-align: center;
}

table.american_HistoryTbl tr td {
  color: #fff;
  background: #ddd;
  text-align: center;
  padding: 8px 1px;
  font-weight: bold;
  font-size: 36px;
}
 
.american_ins {
  width: 100%;
  float: left;
  text-align: center;  
  margin-top: -25px;
}
.american_ins .ins {
  width: 100%;
  float: left;
  background: #3a3a3a;
  border: solid 1px #1f1f1f;
  padding: 10px;
  font-size: 30px;
  color: #fff;
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wid_110prsnt { width: 110%; }
.xImgs {margin-left: 15px;}



.loader_2 {
  border: 4px solid #ffffff;
  border-top: 4px solid #ff0c00;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spins 2s linear infinite;
  margin-left: 10px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}
.loader_1 {
  border: 4px solid #ffffff;
  border-top: 4px solid #ff0c00;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  animation: spins 2s linear infinite;
  margin-left: 10px;
}

@keyframes spins {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.logSubmt {
  display: flex;
  justify-content: center;
}
 


#mainWindow { 
  position: relative; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}


/* @media only screen and (orientation:portrait) {
  body {
      height: 100vw;
      transform: rotate(90deg);
  }
} */
.red {color: red;}
button.windoClose {
  border: none;
  background: transparent;
  position: fixed;
  right: 15px;
  top: 15px;
}
button.windoClose img {
  width: 75px;
}
.btn_1.btn_logout {
  background: linear-gradient(0deg, #e70415, #ff6c62);
  font-size: 28px;
  width: 200px;
  border: solid 1px #ff6969;
}
.btn_1.btn_myAcount {
  background: linear-gradient(#996828, #fbea9a, #996828);
  font-size: 28px;
  width: 200px;
  border: solid 1px #653b0a;
}
.timerDis {
  color: #fff;
  display: flex;
  justify-content: center;
  /* padding-top: 10px; */
  width: 100px;
  height: 100px;
  border: solid 2px;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  background: #ddd0;
}
.loader_Cover {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000000cf;
  z-index: 999;
}
.testLoad {
  position: fixed;
  top: 0;
  background: #ffff;
}

.loaderCover_Box {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #ffc400;
  position: fixed;
  z-index: 1000000;   
 }
 .load_1 .loadImg {
     width: 400px; 
     text-align: center;
     margin: auto;
 }
 .load_1 .loadImg img{
     width: 100%;
 }
 .load_1 {
width: 700px;
margin: auto;
height: fit-content;
top: 0;
bottom: 0;
position: absolute;
left: 0;
right: 0;
}
.loadBox {
width: 700px;
float: left;
padding: 5px;
border-radius: 50px;
border: solid 4px #884026;
margin-top: 7px;
}
.loadBox_2 {
width: 79%;
float: left; 
background: #f00;
padding: 12px;
animation: loadScroll 1.5s infinite;
transform: translateX(140px);
border-radius: 50px;
}
@keyframes loadScroll {
0% { transform: translateX(0px); background: linear-gradient(45deg, #531f0d, transparent); }
50% { transform: translateX(140px); background: linear-gradient(45deg, transparent, #531f0d );}
100% { transform: translateX(0px); background: linear-gradient(45deg, #531f0d, transparent);}
}
.version_1 {
  width: 80px;
  height: 80px;
  position: fixed;
  bottom: 5px;
  right: 5px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #ffffff8f;
}

.gamePage {
  width: 100%;
  float: left;
  height: 1250px;
  background-size: cover;
}
.fd.btnsGaMe {
  display: flex;
  flex-wrap: wrap;
  height: 1140px;
}
.ht_56 {height: 56px;}
.gameBoxMain {
  width: 2350px;
  height: 1250px;
  overflow: hidden;
  /* background: #dddd; */
  margin: auto;
  transform-origin: left top;
  position: relative;
}
/* .gameBox {
  width: 2350px;
  height: 1250px;
  overflow: hidden; 
  margin: auto;
  transform-origin: left top;
  position: relative;
} */
div#root {
  /* overflow: hidden; */
  width: 100%;
  /* height: 100vh; */
  float: left;
  background: #000;
}
.fd.inp_elm label{
  font-size: 25px;
}
.chkBox input {
  width: 25px;
  height: 25px;
}
.chkBox {
  display: flex;
  align-items: center;
}
.gameOne {
  width: 400px;
}

.maxWid_1120 {
  max-width: 1120px;
  margin: auto;
}

/*********************** logo box ******************/
.logoBox {width: 210px;float: left; background: transparent;padding: 10px;}
  .logoBox .box_1Logo {width: 28%; float: left;position: relative;margin-right: 5%;
    margin-bottom: -10px;}
  .logoBox .box_1Logo img:nth-child(2) {width: 100%;
  z-index: 2;position: relative;}
  .logoBox .box_1Logo img:nth-child(1) {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 0; 
    animation: iconAnim 2s infinite;
  }
  .logoBox .box_2Logo {width: 65%;float: left;position: relative;}
  .logoBox .box_2Logo img {width: 100%;float: left;}
  
/*********************** logo box ******************/


.funBal {
  background: #870000;
  text-align: center;
  color: #fff;
  padding: 1px;
  font-size: 30px;
  margin-bottom: 5px;
}

.funBal div:nth-child(1) {
  background: linear-gradient(#830000, #580000);
  padding: 0px;
}
.funBal div:nth-child(2) {
  background: #210000;
  padding: 4px 0px;
}

.ar_bt_1 {
  background: linear-gradient(#800000, #4a0000, #800000);
  border: none;
  color: #fff;
  padding: 24px 20px;
  border-radius: 7px;
  text-transform: uppercase;
  font-size: 27px;
  transition: all 0.5s;
  border: solid 3px #ff858569;
}

.errorPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  text-align: center; 
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: none;
  z-index: 999999;
}
.errorPopup .popupTooLong {
  position: fixed;
  font-size: 14px;
  width: 350px;
  height: -moz-fit-content;
  height: fit-content;
  background: #1e1e1e;
  border-radius: 5px;
  color: #c3c3c3;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
}
.awayPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00223eed;
  color: #fff;
  text-align: center; 
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: none;
  z-index: 999999;
}

.awayPopup .popupTooLong {
  position: fixed;
  font-size: 14px;
  width: 350px;
  height: -moz-fit-content;
  height: fit-content;
  background: #000;
  border-radius: 5px;
  color: #c3c3c3;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
}
.btn_iosPopup{
  color: #080;
  border: solid 1px;
  margin-right:10px;
  font-size: 28px;
}

.fd.btns_Reconnect button:nth-child(1) {
  color: #080;
  border: solid 1px;
  margin-right:10px;
}
.fd.btns_Reconnect button:nth-child(1):hover {
  background: #080;
  color: #fff;
  border: solid 1px #080; 
}


.fd.btns_Reconnect button:nth-child(2) {
  color: #f00;
  border: solid 1px;
}
.fd.btns_Reconnect button:nth-child(2):hover {
  background: #f00;
  color: #fff;
  border: solid 1px #f00;
}
.fd.btns_Reconnect {
  margin-top: 15px;
}
.fd.btns_Reconnect button {
  background: transparent; 
  padding: 5px 12px;
  border-radius: 5px;
  transition: all 0.5s;
  width: 120px;
}
body {overflow: hidden;height: 100vh;}
.rotateWindow {
  position: fixed;
  background: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.isMobileTrue img {
  width: 80px;
  animation: mobileAnim 2s infinite;
  margin-bottom: 15px;
}
@keyframes mobileAnim {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(-90deg);}
  100% {transform: rotate(0deg);}
}
.isMobileTrue {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: var(--clr_1a);
  text-align: center;
  color: #fff;
}

.isMob {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.iosFetchSounds {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00223eed;
  color: #fff;
  text-align: center; 
  justify-content: center;
  align-items: center;
  font-size: 40px; 
  z-index: 999999;
}

.iosFetchSounds .popupTooLong {
  position: fixed;
  font-size: 25px;
  width:40%;
  height: -moz-fit-content;
  height: 20%;
  background: #000;
  border-radius: 5px;
  color: #c3c3c3;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
}

.zin_9 {z-index: 9;}

.text-center{
  text-align: center;
}
.i_symbol {
  cursor: pointer;
  width: 70px;
  height: 70px;
  background: transparent;
  border: none;
}


.payTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 61%;
  border: 10px #8d2c1b solid;
  border-radius: 10px;
  position: relative;    
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s;
}

@-webkit-keyframes animatetop {
  from {
      top: -300px;
      /* -webkit-transition: all 0.5s ease-in-out; */
      /* transform: rotate(500deg); */
      transform-style: preserve-3d;
  transform: rotateY(-60deg);
      opacity: 0
  }

  to {
      top: 0;
      opacity: 1;
      /* -webkit-transition: all 0.5s ease-in-out; */
      transform: rotate(0deg);
  }
}


@keyframes animatetop {
  from {
      /* top: -300px; */
      /* -webkit-transition: all 0.5s ease-in-out; */
      /* transform: rotate(500deg); */
      transform-style: preserve-3d;
      transform: rotateY(-60deg);
      opacity: 0
  }

  to {
      /* top: 0; */
      opacity: 1;
      /* -webkit-transition: all 0.5s ease-in-out; */
      transform: rotate(0deg);
  }
} 


#payTable td {
  border: 5px solid #cd7f32;
  text-align: center;
  padding: 2px;
  color: #000;
  font-size: 30px;
  font-weight: bold;
  font-family: 'SAGONABOOKBOLD';

}

#payTable th {
  border: 5px solid #cd7f32;
  text-align: center;
  padding: 2px;
  color: #000;
  font-size: 35px;
  font-weight: bold;
  font-family: 'SAGONABOOKBOLD';
}

#payTable tr:nth-child(even) {
  background-color: #ffbf00;
  color: #000;
}

#payTable tr:nth-child(odd) {
  background-color: #eba937;
  color: #000;
}

#tableHead th {
  background-color: #cd7f32;
  width: 150px !important;
}

.payout_head {
  font-size: 37px !important;
}

.payout_table {
  display: none;
}
/* #infoIcon {
  position: absolute;
  right: 84px;
} */
.PayoutPage {
  display: none;
  position: relative;
  z-index: 150;
}
.payout_table {
  position: absolute;
  top: 34px;
  right: 547px;
  width: 24%;
  height: 28%; 
  z-index: 50022;
  /* display: block; */
}
.ertimer_payTable{
  position: absolute;
  top: 5px;
  right: 524px;
  width: 70px;
  height: 70px;
  z-index: 9999;

}
.artimer_payTable {
  position: absolute;
  top: 5px;
  right: 524px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}
.ft_payTable {
  position: absolute;
  top: 5px;
  right: 582px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}
.art_payTable {
  position: absolute;
  top: 5px;
  right:1156px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}
.ftt_payTable {
  position: absolute;
  top: 35px;
  right: 514px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}
.mtt_payTable {
  position: absolute;
  top: 15px;
  right: 820px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}

.fc_payTable {
  position: absolute;
  top: 6px;
  right: 450px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}

.kn_payTable {
  position: absolute;
  top: 25px;
  left: 1797px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}

.ab_payTable {
  position: absolute;
  top: 225px;
  right: 60px;
  width: 70px;
  height: 70px;
  z-index: 999;
}

.bingo_payTable {
  position: absolute;
  top: 113px;
  right: 400px;
  width: 85px;
  height: 85px;
  cursor: pointer;
  z-index: 10;
}
.bgno_dup_payTable {
  position: absolute;
  top: 25px;
  left: 1700px;
  width: 70px;
  height: 70px;
  z-index: 9999;
}

.mw_payTable {
  position: absolute;
  top: 50px;
  right: 260px;
  width: 70px;
  height: 70px;
  z-index: 999;
}

.dt_payTable {
  position: absolute;
  top: 155px;
  right: 570px;
  width: 80px;
  height: 80px;
  z-index: 999;
}




/* new Loader start */

.Loadercontainer{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-color: #000;

  transition: filter 0.5s ease-in-out, opacity 0.5s ease-in-out;
  filter: blur(10px); /* Start with blur */
  opacity: 1; 
}
.Loadercontainer.loaded {
  filter: blur(0);
}

.MainLoadercontainer {
  height: 100vh;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
}


.loading_bar {
  position: absolute;
  z-index: 1;
  bottom: 18%;
  width: 100%;
  max-width: 510px;
  height: 30px;
  border: 2px solid #f2f9fac7;
  border-radius: 30px;
  overflow: hidden;
  animation-delay: 2s;
  background-color: #c1cad12b;
}

@keyframes loading-animation {
  0% { width: 0%; opacity: 0.7; }
  100% { width: 100%; opacity: 0.7; }
}

@keyframes glow-effect {
  0% { box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3); }
  50% { box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8); }
  100% { box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3); }
}

.loading-fill {
  height: 100%;
  background: linear-gradient(90deg, rgb(17 16 16 / 0%), rgb(255 255 255 / 0%), rgb(17 16 16 / 94%));
  animation: loading-animation 1s infinite ease-in-out;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
}
/* new Loader end */



/* pogress bar start */

.containerBaritem {
  width: 100%;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 21%;
}

.progress2 {
  border-radius: 30px;
  background-color: #fff;
  width: 100%;
  max-width: 510px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  border: 2px solid #9ed7e2;
}

.progress-bar2 {
  height: 100%;
  border-radius: 30px;
  transition: width 0.4s linear;
  box-shadow: 0 0 10px #f3c623;
  /* background: linear-gradient(7deg, #066686, #0adbf5); */
  background: linear-gradient(80deg, #efb312, #f5f15a, #0f9bbc, #1640bedb);
  
}
.loader {
  font-size: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%,4%);
  color: #000;
  font-weight: bold;
}

/* pogress bar end */



