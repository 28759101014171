button{
    background-color: transparent;
    border: none;
    outline: none;
}
.antetextportraiteTP {
    position: absolute;
    top: 49.6%;
    right: 52%;
    /* transform: scale(2.6,0.5); */
    color: yellow;
    font-size: 44px;
    font-family: "Poppins";
    /* left: 42%; */
    pointer-events: none;
    z-index: 1;
    width: 14%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.antetextwindowsTP {
    position: absolute;
    top: 50%;
    /* right: 48.5%; */
    color: yellow;
    font-size: 27px;
    font-family: 'Poppins';
    z-index: 1;
    height: 141px;
    width: 118px;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    align-items: center;
    /* text-align: center; */
    left: 0;
    right: 0;
    font-weight: bolder;
    margin: auto;
    pointer-events: none;
}

.antetextlnspeTP {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    position: absolute;
    width: 20%;
    height: 20%;
    top: 47%;
    right: 48%;
    color: yellow;
    font-size: 39px;
    font-family: 'Poppins';
    pointer-events: none;
}

.useridlandscapeTP {
    position: absolute;
    bottom: 4.5%;
    right: 8%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
}

.useridwindowsTP {
    position: absolute;
    top: 0.5%;
    left: 9%;
    /* right: 6%; */
    color: #f7f812;
    font-size: 44px;
    font-family: 'Poppins';
    z-index: 1;
}

.useridportraiteTP {
    position: absolute;
    top: 8.5%;
    left: 10%;
    /* transform: scale(1.7,0.7); */
    /* right: 72%; */
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
}

.balancewindowsTP {
    position: absolute;
    top: 0.5%;
    left: 28%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}

.balanceportraiteTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    /* top: 0%;
    left: 28%; */
    /* transform: scale(1.5,0.6); */
    top: 4%;
    left: 20%;
    color: #fffe00;
    font-size: 42px;
    font-family: 'Poppins';
    font-weight: bold;
}

.balancelndscapeTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 3%;
    left: 15%;
    color: #fffe00;
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: bold;
}

.betsportraiteTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 4%;
    /* left: 64%; */
    left: 62%;
    /* transform: scale(2.5,0.55); */
    color: #fffe00;
    font-size: 42px;
    font-family: 'Poppins';
    font-weight: bold;
}

.betslandspeTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 13%;
    left: 8%;
    color: #fffe00;
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: bold;
}

.betswindowsTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 0.3%;
    left: 49%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}
.winswindowsTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 1%;
    /* right: 23.7%; */
    /* background: red; */
    left: 75%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
    /* width: 133px; */
    /* height: 51px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.winsportraiteTP {
    position: absolute;
    top: 4%;
    right: 8%;
    /* right: 20%; */
    color: #fffe00;
    /* transform: scale(2.5,0.55); */
    font-size: 42px;
    width: 10%;
    /* height: 13%; */
    font-family: 'Poppins';
    font-weight: bold;
}

.winslandspeTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 4%;
    left: 31%;
    /* right: 12%; */
    color: #fffe00;
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: bold;
}

.drawtextportraiteTP {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 83%;
    left: 46%;
    /* transform: scale(1.4,0.5); */
    color: white;
    font-size: 47px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}

.betportraiteTP {
    /* position: absolute;
    color: white;
    font-size: 40px;
    top: 75.5%;
    left: 76.5%;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 2; */

    position: absolute;
    top: 73.5%;
    left : 74%;
    color: #fffcfc;
    font-size: 40px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 10;
    width: 11%;
    height:6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    pointer-events: none; 
    
}

.foldtextportraiteTP {
    position: absolute;
    color: white;
    font-size: 40px;
    top: 66.5%;
    left: 83%;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 10;
    pointer-events: none;
    width: 11%;
    height: 6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.topcardsTP {
    /* background: red; */
    width: 51%;
    position: absolute;
    top: 20%;
    left: 24%;
    height: 10%;
}

.betpannelTropicalstud {
    /* background: yellow; */
    position: absolute;
    top: 2%;
    left: 0%;
    width: 100%;
    height: 89%;
}

.drawtextlandspeTP {
    position: absolute;
    top: 91%;
    left: 64%;
    /* transform: translate(639px,367px); */
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 3;
}


.drawtextwindowsTP {
    position: absolute;
    top: 83%;
    left: 57.6%;
    color: white !important;
    font-size: 35px;
    font-family: monospace;
    font-weight: bold;
    pointer-events: none;
    z-index: 1;
    width: 125px;
    text-align: center;
    /* background: blue; */
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}

.drawtextPortraitTP{
    position: absolute;
    top: 78.8%;
    left: 61.5%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 10%;
    height: 5%;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}


.drawtextLndspTP {
    position: absolute;
    top: 78.2%;
    left: 77%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 6%;
    height: 8%;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.buytextPortraitTP{
    position: absolute;
    top: 79%;
    left: 61%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    /* width: 99px; */
    text-align: center;
    width: 11%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-wrap: anywhere;
    align-items: center;
    flex-wrap: wrap;

}

.buytextLndspTP{
    position: absolute;
    top: 77%;
    left: 77%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    width: 6%;
    height: 8%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-wrap: anywhere;
    align-items: center;
    flex-wrap: wrap;

}
.betwindows_rebetTP {
    position: absolute;
    top: 75.5%;
    left: 66.4%;
    color: #fff;
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 700;
    z-index: 2;
    /* background: red; */
    pointer-events: none;
    width: 138px;
    height: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}
.betwindowsTP {
    position: absolute;
    top: 75.5%;
    left: 66.4%;
    color: #fff;
    font-size: 35px;
    font-family: monospace;
    font-weight: 700;
    z-index: 2;
    /* background: red; */
    pointer-events: none;
    width: 138px;
    height: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}
.betportraitTP{
    position: absolute;
    top: 73.5%;
    left: 74%;
    color: #fffcfc;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 11%;
    height: 6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    line-height: 1;
}

.betLandscapeTP {
    position: absolute;
    top: 63.5%;
    left: 82.5%;
    color: #fffcfc;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 6%;
    height: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    line-height: 1;
}
.foldtextwindowsTP {
    position: absolute;
    top: 62%;
    left: 74%;
    font-size: 35px;
    font-family: monospace;
    font-weight: 700;
    color: #ffffff !important;
    z-index: 1;
    /* background: red; */
    width: 128px;
    height: 128px;
    text-align: center;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.winPopupForMptIntlTP{
    visibility: hidden;
    opacity: 0;
}

.winPopupForMptSwTP{
    position: absolute;
    top: 1930px;
    left: 4%;
    width: 17%;
    height: 69px;
    border: solid 6px yellow;
    border-radius: 50px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* animation: blink normal 1s infinite ease-in-out; */
    animation: borderBlink 0.3s infinite ease-in-out;
}

.winPopupForMLspTP {
    position: absolute;
    top: 485px;
    left: 71.2%;
    width: 8%;
    height: 85px;
    border: solid 6px yellow;
    border-radius: 50px;
    /* animation: blink normal 1s infinite ease-in-out; */
    animation: borderBlink 0.3s infinite ease-in-out;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    
}



.winpopup_tropical {
    position: absolute;
    /* margin-top: 36%; */
    top: 700px;
    left: 63.5%;
    width: 278px;
    height: 104px;
    border: solid 8px yellow;
    border-radius: 50px;
    animation: borderBlink 0.4s infinite ease-in-out;
    /* border: 5px yellow; */
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
/* Keyframes for blinking border */
@keyframes borderBlink {
    0% {
        border-color: yellow;
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: yellow;
    }
}


.popupbetshighMobileIntialTP{
    visibility: hidden;
    opacity: 0;
    scale: 0;
    

}
.popupbetshighwindowsTP {
    position: relative;
    top: 424px;
    left: 732px;
    width: 878px;
    height: 319px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.poptext_betshighTP {
    position: absolute;
    /* top: 12px; */
    padding: 94px 21px;
    font-size: 30px;
    color: #230303;
    font-weight: bold;
    font-family: 'Poppins';
}

.poptextBetshighwindowsTP{
    position: relative;
    /* top: 12px; */
    padding: 10px 77px;
    font-size: 40px;
    color: #230303;
    font-weight: bold;
    font-family: 'Poppins';
    text-align: center;
}

.popupbetshighwindowsTP {
    position: relative;
    top: 188px;
    left: 728px;
    width: 900px;
    height: 296px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popupbetshighPortraitTP{
    position: absolute;
    top: 780px;
    left: 145px;
    width: 900px;
    height: 400px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 1500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popupbetshighLndspTP{
    position: absolute;
    top: 410px;
    right: 0;
    left: 0;
    /* bottom: 0; */
    margin: auto;
    width: 900px;
    height: 400px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 1500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
}


.poptext_betshighTP {
    position: absolute;
    /* top: 12px; */
    text-align: center;
    padding: 94px 21px;
    font-size: 37px;
    color: #230303;
    font-weight: bold;
    font-family: 'Poppins';
}

.poptextBetshighPortraitTP{
    position: relative;
    /* top: 12px; */
    padding: 10px 77px;
    font-size: 50px;
    color: #230303;
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
    line-height: 1;
}


.attention_textTP {
    position: absolute;
    top: 15px;
    margin-left: 209px;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    padding: -6px 167px;
    font-size: 65px;
    color: #1d0303;
    font-weight: bold;
    font-family: 'Poppins';
}

.attentionTextPortraitTP{
    position: relative;
    font-size: 80px;
    color: #1d0303;
    font-weight: bold;
    font-family: "Poppins";
    margin-bottom: 6%;
}

.chipglow_bonusTP {
    position: absolute;
    top: 693px;
    /* margin-bottom: 74%; */
    /* margin-top: 831.5px; */
    left: 1618px;
    width: 131px;
    height: 131px;
    border-radius: 50%;
    z-index: 1;
    border: 10px solid yellow;
    animation: blink normal 0.5s infinite ease-in-out;
}
/* .chipglow_russian {
    position: absolute;
    top: 754px;
    left: 1434px;
    width: 131px;
    height: 131px;
    border-radius: 50%;
    z-index: 1;
    border: 10px solid yellow;
    animation: blink normal 0.5s infinite ease-in-out;
} */
.drawChipDivTP{
    position: absolute;
    width: 12%;
    bottom: 1%;
    left: -58px;
    z-index: -6;
    transform: translate(610px, -27px);
    /* transform: translate(190px, 1200px); */
}
.drawChipDivPortraiteTP{
    position: absolute;
    width: 12%;
    bottom: 1%;
    left: -58px;
    z-index: -6;
    transform: translate(610px, -27px);
    /* transform: translate(190px, 1200px); */
}

.drawChipDivLndspTP {
    position: absolute;
    width: 6%;
    right: 7.15%;
    top: 50.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.winamt_TropicalStud {
    position: absolute;
    margin-top: 1%;
    /* top: 5%; */
    left: 1%;
    color: #ffffff;
    font-size: 37px;
    height: 58px;
    width: 258px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: red; */
    text-align: center;
}
.winamtMpSwTP{
    color: #ffffff;
    font-size: 40px;
    text-align: center;
}

.betchipsaddtodrawPositionTP{
 top: 81px;
 left: -58px;    
 width: 93%;
}

.betchipsaddtodrawTP{
    position: absolute;
    width: 100%;
    /* bottom: 5%; */
    /* left: 44%; */
    z-index: 3;
}
.betTextaddtodrawTP{
    width: 100%;
    display: flex;
    color: white;
    justify-content: center;
    z-index: 3;
    font-size: 38px;
    position: relative;
    left: -5px;
    top: 47px;
    /* font-family: 'Poppins'; */
    font-family: 'Poppins';
    font-weight: bold;
}
.betTextaddwLndspTP {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 3;
    font-size: 38px;
    position: relative;
    /* font-family: 'Poppins'; */
    font-family: 'Poppins';
    font-weight: bold;
}



.foldtextportraitTP{
    position: absolute;
    top: 66.5%;
    left: 83%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11%;
    height: 6%;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

}
.foldtextLandscapeTP{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 84.5%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 8%;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    line-height: 1;

}

.rebettextwindowsTP {
    position: absolute;
    top: 66%;
    left: 71.6%;
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
    width: 105px;
    text-align: center;
    overflow-wrap: anywhere;
    /* background: red; */
}
.rebettextportraitTP{    
    position: absolute;
    top: 66.5%;
    /* left: 83%; */
    left: 83.5%;
    color: #fffcfc;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    /* width: 11%; */
    width: 10%;
    height: 6%;
    text-align: center;
    overflow-wrap: anywhere;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;

}
.rebettextwindowsTP {
    position: absolute;
    top: 63%;
    left: 74.3%;
    color: white;
    font-size: 31px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 23;
    width: 105px;
    text-align: center;
    display: flex;
    overflow-wrap: anywhere;
    /* background: red; */
    width: 117px;
    height: 128px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.royalflshwindowsTP {
    position: absolute;
    top: 27%;
    left: 29.7%;
    /* transform: translate(363px,126px); */
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.strightflushwindowsTP {
    position: absolute;
    top: 31%;
    left: 28.8%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fourofkindwindowsTP {
    position: absolute;
    top: 35%;
    left: 29.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fullhouseTP {
    position: absolute;
    top: 39%;
    left: 31.7%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.flushTPS {
    position: absolute;
    top: 43%;
    /* left: 37.9%; */
    left: 34.4%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.strightwindowsTP {
    position: absolute;
    top: 47%;
    right: 59%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.threekindTP {
    position: absolute;
    top: 51%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.twopairTPS {
    position: absolute;
    top: 55%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.onepairTPS {
    position: absolute;
    top: 59%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.acekingTPS {
    position: absolute;
    top: 63%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}


.royalflshportraiteTP {
    position: absolute;
    top: 53%;
    /* left: 13%; */
    left: 11%;
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.strightportraiteTP {
    position: absolute;
    top: 55%;
    /* left: 11.5%; */
    left: 11%;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fourofkindportraiteTP {
    position: absolute;
    position: absolute;
    top: 57%;
    left: 11%;
    /* left: 11.5%; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fullhouseportraiteTP {
    position: absolute;
    top: 59%;
    left: 11%;
    /* left: 18%; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.flushportraiteTP {
    position: absolute;
    top: 61%;
    left: 11%;
    /* left: 25.3%; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.strightTPS {
    position: absolute;
    top: 53%;
    left: 55%;
    /* transform: translate(234px,449px); */
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.threekindportraiteTP {
    position: absolute;
    top: 55%;
    /* transform: translate(234px,470px); */
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    /* font-size: 38px; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 55%;
}

.twopairportraiteTP {
    position: absolute;
    /* transform: translate(236px,491px); */
    top: 57%;
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    /* font-size: 38px; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 55%;
}

.onepairportraiteTP {
    position: absolute;
    /* transform: translate(236px,515px); */
    top: 59%;
    left: 55%;
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.acekingportraiteTP {
    position: absolute;
    /* transform: translate(236px,541px); */
    top: 61%;
    left: 55%;
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}


.royalflshlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 28%;
    /* left: 24%; */
    left: 60%;
}

.strightflushlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 31.5%;
    /* left: 24%; */
    left: 60%;
}

.fourofkindlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 35%;
    /* left: 24%; */
    left: 60%;
}

.fullhouselndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    /* left: 24%; */
    left: 60%;
    top: 38.5%;
}

.flushlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 41.5%;
    /* left: 24%; */
    left: 60%;
}

.strightlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    /* top: 26%; */
    top: 54%;
    left: 60%;
}

.threekindlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 60%;
    /* top: 29.5%; */
    top: 57%;
}

.twopairlndspeTP {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 60%;
    /* top: 33%; */
    top: 60%;
}

.onepairlndspeTP {
    position: absolute;
    /* transform: translate(-28px, -258px); */
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 60%;
    /* top: 36.5%; */
    top: 63%;
}

.acekinglndspeTP {
    position: absolute;
    /* transform: translate(-39px, -218px); */
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    z-index: 1;
    left: 60%;
    /* top: 40%; */
    top: 66%;
}
.bet-text-addtoBet-TP {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 667px;
    left: 1411px;
    color: #ffffff;
    font-size: 22px;
    font-family: 'Poppins';
    /* color: rgb(14, 14, 236); */
    /* color: #370707; */
    z-index: 7;
    width: 71px;
    height: 62px;
    font-weight: bold;
    /* background: black; */
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
}

.bet-text-addtoBet-portrait-TP {
    position: absolute;
    font-size: 38px;
    left: 42.5%;
    bottom: -21.5%;
    /* top: 129.6%; */
    z-index: -2;
    display: flex;
    flex-direction: column;
    width: 10%;
    /* background: red; */
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    font-family: 'Poppins';
    font-weight: bold;
}
.bet-text-addtoBet-lndsp-TP {
    position: absolute;
    font-size: 38px;
    width: 6%;
    height: 5%;
    /* background: red; */
    font-family: 'Poppins';
    font-weight: bold;
    top: 0.5%;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2%;
    z-index: -2;
}

.bet-text-addtoBet20TP{
    color:#ffffffe1;
}

.bet-text-addtoBet10TP{
    color:#ffffffe1;
}


.bet-text-addtoinsurence10TP{
    color:#ffffffe1;
}

.bet-text-addtodrawTP {
    position: absolute;
    font-size: 22px;
    left: 39.8%;
    top: 54%;
    z-index: 19;
    display: flex;
    width: 2%;
    justify-content: center;
    /* opacity: 0; */
    /* background: red; */
    /* visibility: hidden; */
    font-family: 'Poppins';
    font-weight: bold;
}
.bet-text-addtodraw20TP{
    color:#ffffffe1;
}


.bet-text-addtodraw10TP{
    color:#ffffffe1;
}
.drawtextopacityTP{
    opacity: 0.5;

}
.drwnodropTP {
    cursor: no-drop;
}

.bonuspaytableTP {
    position: absolute;
    top: 5%;
    
}


.bonusChipsContainerwindowsTP {
    /* position: absolute;
    top: 960px;
    left: 56%;
    z-index: 5;
    width: 4%;
    display: flex;
    justify-content: center; */
    pointer-events: none;
}


.bonusChipsForwindowsTP{
    position: absolute;
    width: 100%;
}
.bonusChipsForwindows_val10_TP{
    position: absolute;
    color: #ffffff;
    top: 676px;
    left: 1238px;
    font-size: 22px;
    font-family: 'Poppins';
    z-index: 15;
    /* background: red; */
    text-align: center;
    height: 47px;
    width: 61px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    pointer-events: none;
    align-items: center;
}
.bonusChipsForwindowsTP {
    position: absolute;
    color: #ffffff;
    top: 676px;
    left: 1238px;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Poppins';
    z-index: 15;
    text-align: center;
    height: 46px;
    width: 62px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    /* align-items: start; */
}


.chip10centerbnsTP {
    position: absolute;
    top: 642px;
    left: 1210px;
    z-index: 15;
    width: 5%;
    /* pointer-events: none; */
    /* z-index: 100; */
}
.chip100centerbnsTP {
    position: absolute;
    top: 645px;
    left: 1210px;
    z-index: 15;
    width: 5%;
    pointer-events: none;
}



.chip10centerpositionTP {
    position: absolute;
    top:642px;
    /* top: 613.4px; */
    left: 1054px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}
.chip20centerpositionTP {
    position: absolute;
    top: 643px;
    left: 1054px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}

.chip50centerpositionTP {
    position: absolute;
    top: 642px;
    left: 1053px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}

.chip100centerpositionTP {
    position: absolute;
    top: 642px;
    left: 1053px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}

.chip500centerpositionTP {
    position: absolute;
    top: 642px;
    left: 1053px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}
.chipsstoredcontainerLndspTP{
    position: relative;
    left: 46.8%;
    /* right: 0; */
    margin: auto;
    /* top: 585px; */
    top: 605px;
    bottom: 0;
    z-index: 2;
}
.chip-center-position-TP{
    /* position: relative;
    width: 12%;
   
    top: -2165px;
    left: 37%;
    display: flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    width: 11%;
    /* top: 0; */
    top: -1052px;
    left: 36.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.chip-center-position-lndsp-TP {
    position: relative;
    width: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 36px;
    right: 99px;
}



.chip20centerpositionPortraitTP{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
}



.container_playerdealerTP {
    position: absolute;
    top: 568px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 57%;
   
}
#container_playerdealerTP h1::before {
    content: "DEALER";
    color: #a1a1a1;
    position: absolute;
    -webkit-text-stroke: 3px #bcd7bc;
}
#container_playerdealerTP h2::before {
    content: "PLAYER";
    color: #a1a1a1;
    position: absolute;
    -webkit-text-stroke: 3px #bcd7bc;
}

.Dealer_windowsTP {
    font-family: 'Poppins';
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    left: 605px;
    top: -310px;
}
.Player_windowsTP {
    font-family: 'Poppins';
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    right: 558px;
    top: 215px;
}

.cntr_pyrdlr_prtTP{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* height: 74%; */
    height: 110%;
    position: absolute;
    /* top: 22%; */
    top: 10%;
    font-size: 38px;
    font-family: "Poppins";
    padding: 50px 0;
}
.cntr_pyrdlr_lnspTP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75%;
    position: absolute;
    top: 10%;
    left: 0%;
    font-size: 38px;
    font-family: "Poppins";
    /* margin: auto; */
}



.antebetChipPortrait20TP{
    position: absolute;
    z-index: 9;
    font-size: 38px;
    color: #ffffff;
    pointer-events: none;
    display: flex;
    width: 12%;
    justify-content: center;
    font-family: "Poppins";
}


.bonusChipsContainerForPortraitTP {
    position: absolute;
    top: 1047px;
    left: 51.5%;
    z-index: 5;
    width: 11%;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.bonusChipsCntrFrLndspTP {
    position: absolute;
    left: 91px;
    top: 37px;
    z-index: 5;
    width: 6%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    align-items: center;
}


.bonusChipsForPortraitTP{
    position: absolute;
    width: 100%;
}
.bonusChipsTextForPortraitTP{
    position: absolute;
    color: #ffffff;
    top: 43px;
    font-size: 38px;
    z-index: 10;
    text-align: center;
}

.bonusChipsTextfrLndspTP {
    position: absolute;
    color: #ffffff;
    font-size: 38px;
    z-index: 10;
    text-align: center;
}

.bnspoinerTP {
    cursor: no-drop;
    opacity: 0.7;
}

.nonpointerTP{
    pointer-events: none;
}
.addpointerTP {
    cursor: pointer;
}
.gamecover_enbledisableTP {
    position: absolute;
    /* background: red; */
    opacity: 0.5;
    width: 3000px;
    height: 1245px;
    top: 5%;
    left: 5%;
}
.windowsinfoTP {
    position: absolute;
    /* top: 932px; */
    font-size: 27px;
    font-family: 'Poppins';
    z-index: 5;
    color: #ffffff;
    /* background: red; */
    width: 419px;
    height: 254px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    left: 0px;
    right: 0;
    margin: auto;
    padding: auto;
}
.portraitinfoTP {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0% 3% 0% 3%;
    justify-content: center;
    top: 2010px;
    position: absolute;
    font-size: 38px;
    font-family: "Poppins";
    z-index: 5;
    color: #ffffff;
    height: 130px;
    align-items: center;
}

.lndspinfoTP {
    position: absolute;
    /* top: 890px; */
    top: 420px;
    font-size: 35px;
    font-family: "Poppins";
    /* z-index: 5; */
    z-index: 0;
    color: #ffffff;
    width: 23%;
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-weight: bold; */
    padding: 1%;
    border: 4px solid #00ffadb0;
    border-radius: 10%;
    left: 10%;
    /* right: 0; */
    /* margin: auto; */
}
.insurencenodropTP{
    cursor: no-drop;
    opacity: 0.5;
}

  
.windowspokerrankingTP {
    position: absolute;
    top: 930px;
    /* bottom: 19px; */
    left: 44%;
    color: aliceblue;
    font-size: 33px;
    font-family: 'Poppins';
    z-index: 5;
    /* background:linear-gradient(186deg, #e3bb3e1a, #ffb00c); */
    background: linear-gradient(180deg, #ff742300, #000);
    width: 296px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 16px;
    /* border: 2px solid #ffb00c;
    box-shadow: 0px 0px 0px 1px #ffb00c; */
    border: 2px solid #dff4f3;
    box-shadow: 0px 0px 0px 1px #dff4f3;
}

.pokerrankingPortaitTP{
    position: absolute;
    /* top: 1680px; */
    top: 1435px;
    /* margin-top: 20.8%; */
    /* left: 36%; */
    left: 35%;
    color: white;
    font-size: 35px;
    font-family: "Poppins";
    z-index: 15;
    /* background:linear-gradient(186deg, #e3bb3e1a, #ffb00c); */
    background: linear-gradient(180deg, #ff742300, #000);
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0.5%;
    border-radius: 16px;
    /* border: 2px solid #ffb00c;
    box-shadow: 0px 0px 0px 1px #ffb00c; */
    border: 2px solid #dff4f3;
    box-shadow: 0px 0px 0px 1px #dff4f3;
}

.pokerrankingPlayerLndspTP {
    position: absolute;
    top: 1019px;
    /* top: 770px; */
    left: 43%;
    /* left: 63%; */
    color: aliceblue;
    font-size: 38px;
    font-family: "Poppins";
    z-index: 15;
    /* background:linear-gradient(186deg, #e3bb3e1a, #ffb00c); */
    background: linear-gradient(180deg, #ff742300, #000);
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.3%;
    border-radius: 16px;
    /* border: 2px solid #ffb00c;
    box-shadow: 0px 0px 0px 1px #ffb00c; */
    border: 2px solid #dff4f3;
    box-shadow: 0px 0px 0px 1px #dff4f3;
}

.windowspokerrankingdelerTP {
    position: absolute;
    top: 430px;
    /* margin-top: 22%; */
    left: 44.3%;
    color: aliceblue;
    font-size: 30px;
    font-family: 'Poppins';
    z-index: 5;
    /* background:linear-gradient(186deg, #e3bb3e1a, #ffb00c); */
    background: linear-gradient(180deg, #ff742300, #000);
    width: 296px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 16px;
    /* border: 2px solid #ffb00c;
    box-shadow: 0px 0px 0px 1px #ffb00c; */
    border: 2px solid #dff4f3;
    box-shadow: 0px 0px 0px 1px #dff4f3;
}

.pokerrankingdelerPortraitTP{
    position: absolute;
    left: 35%;
    top: 750px;
    color: white;
    font-size: 35px;
    font-family: "Poppins";
    z-index: 15;
    /* background:linear-gradient(186deg, #e3bb3e1a, #ffb00c); */
    background: linear-gradient(180deg, #ff742300, #000);
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5%;
    border-radius: 16px;
    /* border: 2px solid #ffb00c;
    box-shadow: 0px 0px 0px 1px #ffb00c; */
    border: 2px solid #dff4f3;
    box-shadow: 0px 0px 0px 1px #dff4f3;
}
.pokerrankingdelerLndSpeTP {
    position: absolute;
    left: 42.7%;
    /* top: 288px; */
    top: 430px;
    /* top: 790px; */
    color: white;
    font-size: 35px;
    font-family: "Poppins";
    z-index: 15;
    /* background:linear-gradient(186deg, #e3bb3e1a, #ffb00c); */
    background: linear-gradient(180deg, #ff742300, #000);
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.3%;
    border-radius: 16px;
    /* border: 2px solid #ffb00c;
    box-shadow: 0px 0px 0px 1px #ffb00c; */
    border: 2px solid #dff4f3;
    box-shadow: 0px 0px 0px 1px #dff4f3;
}
.antebet_textval10TP {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 683px;
    left: 1081px;
    font-size: 22px;
    font-family: 'Poppins';
    color:#ffffff;
    /* color: #370707; */
    z-index: 19;
    width: 63px;
    font-weight: bold;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    pointer-events: none;
}
.antebet_text20TP {
    pointer-events: none;
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 670px;
    left: 1085px;
    font-size: 22px;
    font-family: 'Poppins';
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 58px;
    height: 61px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.antebet_text50TP {
    position: absolute;
    top: 670px;
    left: 1077px;
    font-size: 22px;
    font-family: 'Poppins';
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 70px;
    height: 55px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
    /* display: inline-block; */
}
.antebet_text100TP {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 675px;
    left: 1077px;
    font-size: 22px;
    font-family: 'Poppins';
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 68px;
    height: 50px;
    /* background: #2b0404; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
     text-align: center;
     pointer-events: none;
}


.chip_10 {
    font-size: 50px;
    color: black;
    font-family: 'Courier New', Courier, 'Poppins';
}

.optionsmenuTP {
    position: fixed;
    top: 6%;
    left: -21.5%;
    height: 611px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

}
.optionsmenu_portraiteTP {
    position: fixed;
    top: 6%;
    left: -41.5%;
    /* height: 611px; */
    height: 400px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

}
.optionsmenu_lndspeTP {
    position: fixed;
    top: 5%;
    left: -22%;
    height: 611px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
/* .exithistoryTP {
    animation: anim 1s ease-in-out forwards;
}

@keyframes anim {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1.5);
    }
} */


.exitoptionmenuTP {
    z-index: 44;
    position: absolute;
    top: 1%;
    left: -23%;
}

.topmenuTP {
    position: absolute;
    top: 0%;
    left: -102.5%;
    height: 94px;
    width: 309px;
    background: linear-gradient(#ffffff, #012303);
}

.optionheadingTP {
    font-size: 37px;
    padding: 32px;
    font-family: 'Poppins';
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgb(44, 22, 1);
}
.limitstextTP {
    position: absolute;
    /* top: 26%; */
    top: 15%;
    left: -87%;
    height: 80px;
    width: 257px;
    background-color: #a9761d;
    border-radius: 36px;
    color: #ffffff;
    font-size: 35px;
    font-family: 'Poppins';
}

.helptextTP {
    position: absolute;
    /* top: 48%; */
    top: 40%;
    left: -87%;
    height: 80px;
    width: 257px;
    background-color: #a91d66;
    border-radius: 36px;
    color: #ffffff;
    font-size: 35px;
    font-family: 'Poppins';
}

.historytextTP {
    position: absolute;
    /* top: 70%; */
    top: 65%;
    left: -87%;
    height: 80px;
    width: 257px;
    background-color: #2da91d;
    border-radius: 36px;
    color: #ffffff;
    font-size: 35px;
    font-family: 'Poppins';


}

.arraowtextTP {
    position: absolute;
    top: 88%;
    left: 16.5%;
    height: 57px;
    width: 201px;
    /* background-color: #1d92a9; */
    border-radius: 36px;
    color: #ffffff;
    font-size: 25px;
    font-family: 'Poppins';
}
.chip_10textTP{
    color:#ffffff;
}
.valtext_windows_chip10TP {
    font-size: 31px;
    font-family: 'Poppins';
    position: absolute;

    font-weight: bold;
    width: 63px;
    height: 75px;
    font-weight: bold;
    /* background: #34bf1b; */
    /* top: 91%; */
    bottom: 63px;
    /* right: 19px; */
    /* left: 5%; */
    z-index: 4;
    right: 202px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.valtext_windowsTP {
    color: #ffffff;
    font-size: 36px;
    font-family: 'Poppins';
    position: absolute;
    font-weight: bold;
    bottom: 5%;
    font-weight: bold;
    width: 72px;
    height: 73px;
    /* background: #b31212; */
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 193px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-items: flex-start; */
}


.valtextPortraitTP{
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 4%;
    z-index: 4;
    right: 44%;
    width: 12%;
    display: flex;
    justify-content: center;
}



.valtextLandscapeTP{
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 48%;
    z-index: 4;
    right: 2.5%;
    width: 5%;
    display: flex;
    justify-content: center;

}

.valtextPortrait1TP{
    color:#ffffff;
    
}
.gridTP table {
    position: absolute;
    width: 14%;
    height: 306px;
    right: 597px;
    top: 337px;
    font-family: arial, sans-serif;
    border-collapse: collapse;
}
.rankingInfoTablePortraitTP{
    position: absolute;
    top: 1640px !important;
    left: 3% !important;
    width: 41% !important;
    height: 462px !important;
    right: 3.5% !important;
    font-family: "Poppins" !important;
    border-collapse: collapse !important;
    border: 4px #edeb8b solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background-color: #000000ed !important;
    /* background-image: linear-gradient(to right, #000000ed, #00000082, #000000ed); */
    z-index: 25 !important;
}

.rankingInfoTableLndspTP {
    position: absolute !important;
    left: 3% !important;
    width: 23% !important;
    height: 462px !important;
    font-family: "Poppins" !important;
    border-collapse: collapse !important;
    border: 4px #edeb8b solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background-color: #000000b3 !important;
    z-index: 25 !important;
    top: 500px !important;
}
/* table tbody{
    position: absolute;
    top: 20%;
    left: 10%;
    transform: translate(-2%, 2%);
} */

/* table thead{
    position: absolute;
    top: 5%;
    width: 100%;
    left: 10%;
    transform: translate(-3%, -5%);
} */




/* .chipanimpoker{
    transform: translate(-956px,-599px);
    animation-duration: 1s;
 } */
 .betpannelrussianwindowsTP {
    position: absolute;
    top: 0%;
    left: -2.1%;
    /* left: 1.2%; */
    height: 100%;
    width: 99%;
}
.bonuswinhgltTP{
    color: #ffffff;
    font-size: 27px;
    font-family: 'Poppins';
    text-align: left;
    /* animation: blink normal 1s infinite ease-in-out; */


}
.bonuswinhglt_portriteTP{
    color: #ffffff;
    font-size: 34px;
    font-family: "Poppins";
    text-align: left;
    /* animation: blink normal 1s infinite ease-in-out; */

}

.numTP {
    color: #a1a1a1;
    font-size: 27px;
    font-family: 'Poppins';
    text-align: left;
}
.bonuspayouthgltTP{
    color: #fdfd7b;
    font-size: 40px;
     font-family: 'Poppins';
    /* animation: blink normal 1s infinite ease-in-out; */


}
.bonuspayouthglt_portraiteTP{
    color: #fdfd7b;
    font-size: 34px;
    font-family: "Poppins";
    /* animation: blink normal 1s infinite ease-in-out; */



}
.numPortraitTP{
    color: #fdfd7b;
    font-size: 34px;
    font-family: "Poppins";
    text-align: left;
}

.windowstableheadTP {
    position: absolute;
    font-family: 'Poppins';
    color: #fffe00;
    font-size: 27px;
    margin-top: -77px;
    /* top: -89%; */
    left: 25%;
}
.portraittableheadTP{
    position: absolute;
    font-family: 'Poppins';
    color: #fffe00;
    font-size: 40px;
    top: 10%;
    left: 5%;
}

.numbersTP {
    height: 5px;
    color: #a1a1a1;
    /* border: 5px solid; */
    width: 50px;
    /* margin-top: 50%; */
}

.numbersvalTP {
    font-size: 26px;
    font-family: 'Poppins';
    text-align: end;
}

.numbersvalPortraitTP{
    font-size: 34px;
    font-family: "Poppins";
    text-align: end;
}

/* .value{
    height: 500px;
    border: 5px solid;
    width: 50px;

} */


.confirmbuy-dealerpopupTP{
    visibility: hidden;
}

.popup_cnfrmbuydealrcardTP {
    position: absolute;
    top: 484PX;
    left: 32%;
    width: 869px;
    height: 250px;
    background-color: #ffffff;
    border-radius: 25px;
    /* z-index: 8; */
    z-index: 30;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    /* box-shadow: 6px 7px 100px peachpuff; */
}
.popup_cnfrmbuydealrcardPortrait_TP{
    position: absolute;
    /* top: 49%; */
    top: 900px;
    left: 13.5%;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 1000;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.nobutton_popupTP {
    position: absolute;
    top: 72%;
    left: 49%;
    width: 137px;
    height: 50px;
    border-radius: 16px;
    color: #ffffff;
    font-size: 24px;
    font-family: 'poppins';
    /* border: 1px solid; */
    background-color: #931111;
}
.nobutton_popup_portraitTP {
    position: absolute;
    top: 65%;
    left: 55%;
    width: 150px;
    height: 70px;
    border-radius: 16px;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    /* border: 1px solid; */
    background-color: #931111;
}

.yesbutton_popupTP {
    position: absolute;
    top: 72%;
    left: 32%;
    color: #ffffff;
    font-size: 24px;
    font-family: 'poppins';
    width: 137px;
    height: 50px;
    border: 2px solid;
    background-color: #536505;
    border-radius: 16px;
}

.yesbutton_popup_portraitTP{
    position: absolute;
    top: 65%;
    left: 30%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    width: 150px;
    height: 70px;
    border: 2px solid;
    background-color: #536505;
    border-radius: 16px;
}

.popup_cnfrmbuydealrcardLndsp_TP{

    position: absolute;
    top: 630px;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    /* z-index: 22; */
    z-index: 1000;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}



    .bonustextTPS {
        position: absolute;
        top: 48.5%;
        left: 54.3%;
        /* transform: translate(746px,292px); */
        font-size: 27px;
        color: #d7b75f;
        font-family: 'Poppins';
        z-index: 1;
        /* background: red; */
        width: 108px;
        overflow-wrap: anywhere;
        height: 177px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }


.chiptoinsurenceTP {
    position: absolute;
    top: 426px;
    left: 1112px;
    z-index: 5;
    width: 4%;
}

.chiptoinsurence_windowsTP {
    position: absolute;
    left: 1133px;
    top: 414px;
    z-index: 5;
    width: 5%;
    pointer-events: none;
}




.chiptoinsurencePortraitTP{
    position: absolute;
    top: 755px;
    left: 42%;
    z-index: 5;
    width: 12%;
    pointer-events: none;
}

.insurenceTP {
    position: absolute;
    top: 66%;
    left: 74.3%;
    color: white;
    font-size: 26px;
    font-family: monospace;
    font-weight: bold;
    z-index: 1;
    width: 118px;
    pointer-events: none;
    text-align: center;
    display: flex;
    /* background: red; */
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}
.insurencePortraitTP{
    position: absolute;
    top: 66.5%;
    left: 83%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 17;
    width: 105px;
    text-align: center;
    /* background: red; */
    overflow-wrap: anywhere;
    line-height: 1;
    width: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    }
    
      
.insuranceTextwndwsChip10TP {
    color:#ffffffe1;
    position: absolute;
    font-size: 30px;
    font-family: 'Poppins';
    left: 49.3%;
    top: 437px;
    z-index: 5;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3%;
    height: 68px;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
}


.LimitsPopupTP {
    position: absolute;
    top: 400px;
    left: 0px;
    right: 0;
    z-index: 34;
    margin: auto;
    width: 750px;
    height: 300px;
    background: repeating-linear-gradient(45deg, orange, transparent 14px);
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins';
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.LimitsPopup_landspeTP {
    position: absolute;
    /* top: 149px; */
    top: 400px;
    left: 0px;
    right: 0;
    margin: auto;
    width: 750px;
    height: 300px;
    background: repeating-linear-gradient(45deg, orange, transparent 14px);
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 38px;
    font-family: "poppins";
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.LimitsPopup_portraiteTP {
    position: absolute;
    top: 850px;
    left: 0;
    right: 0;
    margin: auto;
    width: 750px;
    height: 300px;
    /* background: repeating-linear-gradient(45deg, orange, transparent 14px); */
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 38px;
    font-family: "poppins";
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.insuranceText_windowsTP {
    position: absolute;
    font-size: 22px;
    color: #f1f1f5;
    font-family: 'Poppins';
    left: 49.7%;
    top: 453px;
    z-index: 5;
    /* background: red; */
    /* display: flex; */
    width: 2%;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
}

.insuranceTextPortraitTP{
    position: absolute;
    font-size: 38px;
    color: #f1f1f5;
    font-family: "Poppins";
    left: 42%;
    top: 810px;
    z-index: 5;
    /* display: flex; */
    width: 12%;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
}
.chip-to-insurence-lndspTP{
    position: relative;
    width: 100%;
    top: -180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: -47%;
    pointer-events: none;
}

.chiptoinsurenceLndSpeTP{
    position: absolute;
    width: 6%;
}

.insuranceTextLndspeTP{
    position: absolute;
    font-size: 38px;
    color: #f1f1f5;
    font-family: "Poppins";
    width: 5%;
    text-align: center;
    font-weight: bold;
}


.insuranceTextPortraitChip10TP{
    color:#ffffffe1;
}


.insurenceopacityTP {
    opacity: 0.5;
    cursor: pointer;
}
.noinsurenceTP {
    position: absolute;
    top: 75.1%;
    left: 66.6%;
    /* transform: translate(867px,534px); */
    color: white;
    font-size: 24px;
    font-family: monospace;
    font-weight: bold;
    z-index: 2;
    /* pointer-events: none; */
    width: 133px;
    height: 127px;
    text-align: center;
    overflow-wrap: anywhere;
    display: flex;
    /* background: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.noinsurencePortraitTP{
    position: absolute;
    top: 74%;
    left: 74.5%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 2;
    pointer-events: none;
    text-align: center;
    overflow-wrap: anywhere;
    line-height: 1;
    width: 11%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.bnsblinkTP {
    animation: blink normal 1s infinite ease-in-out;
}


.betactiveTP {
    position: absolute;
    top: 49.3%;
    left: 59.8%;
    width: 157px;
    z-index: 4;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(89deg);
}

.betactivePortraitTP {
    position: absolute;
    top: 46.6%;
    left: 63.5%;
    width: 15%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(89deg);
}

.betactiveLndspTP {
    position: absolute;
    top: 39.5%;
    left: 84%;
    width: 26%;
    z-index: 4;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(89deg);
}

.insurenceactivePortraitTP {
    position: absolute;
    top: 31%;
    left: 38.5%;
    width: 17%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(-91deg);
}

.insurenceactiveLndspTP {
    position: absolute;
    top: -11%;
    left: 42%;
    width: 29%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(270deg);
}
.bnsctivewindowsTP {
    position: absolute;
    top: 49.6%;
    left: 53.4%;
    width: 150px;
    z-index: 1;
    /* border: 168px red solid; */
}

.bnsactivePortraitTP{
    position: absolute;
    top: 46.6%;
    left: 48.5%;
    width: 15%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
}

.bnsactiveLandscapeTP{
    position: relative;
    /* top: 17%; */
    top: 39.4%;
    left: 56%;
    width: 26%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
}

.bettextTP {
    position: absolute;
    top: 54%;
    /* transform: translate(665px,376px); */
    font-size: 27px;
    font-weight: bolder;
    color: #d7b75f;
    font-family: 'Poppins';
    z-index: 1;
    /* background: red; */
    width: 189px;
    text-align: center;
    left: 620px;
    right: 0;
    margin: auto;
    font-weight: bolder;
}


.buydrawtextTP {
    position: absolute;
    top: 49.5%;
    left: 41.2%;
    /* transform: translate(542px,294px); */
    font-size: 24px;
    color: #d7b75f;
    font-family: 'Poppins';
    font-weight: bolder;
    z-index: 1;
    /* background: red; */
    width: 105px;
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.bonustextportraiteTP {
    position: absolute;
    /* transform: translate(237px,410px); */
    top: 48.9%;
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color: #d7b75f;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    /* left: 57%; */
    left: 51%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 10%;
    overflow-wrap: anywhere;
    height: 5%;
}

.bettextportraiteTP {
    position: absolute;
    /* transform: translate(178px,482px); */
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color: #d7b75f;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    top: 48.9%;
    left: 62%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 18%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.buydrawtextportraiteTP {
    position: absolute;
    top: 50.5%;
    /* transform: translate(101px,415px); */
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color:#d7b75f;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    left: 20%;
    /* margin: 0px; */
    /* width: 10%; */
    /* padding: 0px; */
    line-height: 1;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 12%;
}

.bonustextlndspeTP {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 20%;
    overflow-wrap: anywhere;
    position: absolute;
    top: 47%;
    left: 58%;
    font-size: 40px;
    color: #d7b75f;
    font-family: 'Poppins';
    pointer-events: none;
}

.bettextlndspeTP {
    overflow-wrap: anywhere;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 20%;
    width: 28%;
    top: 47%;
    left: 83%;
    font-size: 40px;
    color: #d7b75f;
    font-family: 'Poppins';
    pointer-events: none;
}

.buydrawtextlndspeTP {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    width: 22%;
    position: absolute;
    top: 53%;
    left: 4%;
    font-size: 40px;
    color: #d7b75f;
    line-height: 0.8;
    font-family: 'Poppins';
    pointer-events: none;
}

.royaltocolorTP {
    color: yellow;
}

.royalflshportraitetoTP {
    color: yellow;

}
.crdscoverTP {
    position: absolute;
    right: 849px;
    bottom: -228%;
    /* margin-top: 8%; */
    /* left: 56px; */
    width: 578px;
    height: 232px;
    /* background: red; */
    z-index: 27;
}
.bet_pannelcoverTP {
    position: absolute;
    top: 46%;
    left: 37%;
    width: 630px;
    height: 200px;
    /* background: red; */
    z-index: 27;
}
.buttons_coverTP {
    position: absolute;
    top: 804px;
    left: 49%;
    width: 840px;
    height: 455px;
    /* background: red; */
    z-index: 27;
}
.bet_pannelinsurenceTP {
    position: absolute;
    top: 41%;
    left: 40%;
    width: 495px;
    height: 248px;
    /* background: red; */
    z-index: 27;
}
.PlayerCardpannelcoverPortraitTP{
    display: flex;
    justify-content: center;
    background-color: transparent;
    /* background-color: #e12be68f; */
    position: absolute;
    top: 51%;   
    left: 17%;
    width: 63%;
    height: 11%;
    /* z-index: 21; */
    z-index: 999;
}

.PlayerCardpannelcoverLndspTP{
    display: flex;
    justify-content: center;
    background-color: transparent;
    /* background-color: #e12be68f; */
    position: absolute;
    /* top: 48%; */
    top: 63%;
    left: 32%;
    width: 34%;
    height: 20%;
    z-index: 999;
    /* border: 2px solid red; */
}

.mobileAntiMaskCoverTP{
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    position: absolute;
    top: 42%;
    left: 35%;
    /* width: 20%; */
    width: 35%;
    height: 11%;
    /* z-index: 10; */
    z-index: 999;
    background-color:transparent;
    /* background-color: #e12be68f; */
    /* border-radius: 50%; */

}

.mobileLndspAntiMaskCoverTP{
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    position: absolute;
    top: 45%;
    left: 41%;
    /* width: 8%; */
    width: 18%;
    height: 17%;
    /* z-index: 10; */
    z-index: 999;
    /* background-color: #e12be68f; */
    background-color: transparent;
    /* border-radius: 50%; */
   

} 

.RP_MainDivTP {
    position: absolute;
    position: fixed !important;
    width: 102%;
    height: 101%;
    left: -1%;
    /* background-color: red; */
    /* top: 102%; */
}


.player-dealer-card-containerTP {
    /* border: 2px solid red; */
    /* background-color: #f8124ecf; */
    /* height: 250px; */
    width: 100%;
    position: absolute;
    /* left: 46%; */
    /* top: 30%; */
    z-index: 1;
    display: flex;
    /* display: none; */
    justify-content: center;
    /* border-radius: 100%; */

}

.stage-top-mobile-portrait_TP {
    top: 10%;
    /* top: 7%; */
    left: -1.5%;
}
.stage-top-mobile-landscape_TP {
    /* top: 13%; */
    left: -0.5%;
    /* left: 8%; */
    top: 8%;
    /* left: 2%; */
}


.alertTxtTP {
    color: black;
    font-size: 33px;
    text-align: center;
    /* justify-content: center; */
    position: absolute;
    padding: 20px;
}
.alertTxtPortraitTP{
    color: black;
    font-size: 40px;
    text-align: center;
    justify-content: center;
    position: absolute;
    padding: 20px;
}



.dealerBuyCardTP{
    height: 150px;
    width: 150px;
    /* background-color: red; */
    position: relative;
    left: 44%;
    transform: translate(0%);
    top: 6%;
    width: 12%;
}

.dealerBuyCardDrawButtonPortraitTP{
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}
#dealerChipsAddToBuyButtonTP img{ 
    width: 100%;
}

.dealerBuyCardtextTP {
    position: absolute;
    font-size: 40px;
    /* top: 38%; */
    top: 32%;
    z-index: -2;
    display: flex;
    width: 12%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-family: 'Poppins';
    font-weight: bold;
}

.dealerBuyCardLndspTP{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 6%;
    right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.dealerBuyCardtextLndspTP {
    position: absolute;
    font-size: 38px;
    z-index: -2;
    display: flex;
    width: 12%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-family: 'Poppins';
    font-weight: bold;
}

.playerIdDetailswindowsTP {
    position: absolute;
    /* width: 79%; */
    color: #fff;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-size: 25px;
    left: 53px;
    top: 7%;
    font-family: 'Poppins';
    z-index: 5;
}

.playerIdDetailsPortraitTP{
    position: absolute;
    width: 32%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: end;
    /* font-size: 40px; */
    font-size: 35px;
    left: 65%;
    top: 8.7%;
    font-family: 'Poppins';
}
.playerIdDetailsLandscapeTP {
    position: absolute;
    width: 20%;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 38px;
    bottom: 3%;
    left: 3%;
    font-family: 'Poppins';
}

.minmaxbets_windowsTP {
    position: absolute;
    top: 201px;
    left: 33px;
    font-size: 34px;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    color: white;
}

.minmaxbets_portraitTP {
    position: absolute;
    top: 270px;
    left: 2%;
    font-size: 35px;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    color: #fff;
   
}
.minmaxbets_lndspTP{
    position: absolute;
    top: 250px;
    /* left: 2%; */
    left: 3%;
    font-size: 38px;
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    color: #ffffff;
}

.exit_popupTP{
    position: absolute;
    right: 2%;
    top: 2%;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 100;
}
.historyscaleTP{
    transition: transform 0.5s ease;
    transform:scale(0);
}
.showprevhsrytbleTP {
    position: absolute;
    top: 160px;
    left: 340px;
    width: 1853px;
    /* height: 573px; */
    height: 640px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;
    /* display: flex; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* width: 100%; */
    transform: scale(1) !important;
}

/* Webkit Browsers (Chrome, Safari, Edge) */
.showprevhsrytbleTP::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 10px;
}

/* Track (background of scrollbar) */
.showprevhsrytbleTP::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
    border-radius: 5px; /* Rounded edges */
}

/* Handle (the draggable part) */
.showprevhsrytbleTP::-webkit-scrollbar-thumb {
    background: #888888; 
    border-radius: 5px;
}

/* Handle on hover */
.showprevhsrytbleTP::-webkit-scrollbar-thumb:hover {
    background: #555; /* Even darker grey */
}


/* Ensures spacing between all table cells */
#histry_tropical {
    border-collapse: separate; /* Required for border-spacing to work */
    border-spacing: 10px; /* Adds spacing between cells in both <thead> and <tbody> */
  }
  
  /* Adds padding inside each cell for additional spacing */
  #histry_tropical th,
  #histry_tropical td {
    padding: 10px;
    text-align: center; /* Centers content, optional */
  } 



.showprevhsrytblev::-webkit-scrollbar {
    width: 10px; 
  }
  
  .showprevhsrytble::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;
  }

.histry_bodyTP {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
    /* margin-top: -70px; */
}
.histry_tropical {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.exihstrybuttonTP {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstiryTP {
    font-size: 40px;
    font-family: 'Poppins';
    color: papayawhip;
}
.showprevhsrytbleportraiteTP {
    position: absolute;
    top: 701px;
    left: 13px;
    width: 1221px;
    height: 760px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    transform: scale(1) !important;
}
.historycards{
    position: absolute;
    top: 5px;
    left: 5px;
}
.histry_bodyportraiteTP {
    /* width:900px; */
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
}
.histry_Tropicalportraite {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}
.exihstrybuttonportraiteTP {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstiryportraiteTP{
    font-size: 38px;
    font-family: "Poppins";
    color: papayawhip;
}

.showprevhsrytblelndspeTP {
    position: absolute;
    top: 335px;
    left: 340px;
    width: 1853px;
    /* height: 565px; */
    height: 630px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    transform: scale(1) !important;
}
.histry_bodylndspeTP {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
}
.histry_Tropicallndspe {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}
.exihstrybuttonlndspeTP {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstirylndspeTP{
    font-size: 38px;
    font-family: "Poppins";
    color: papayawhip;
}
.cardsimagesTP {
    width: 33px;
}

.GamerulescontainerTP {
    position: absolute;
    height: 1221px;
    width: 50%;
    background: #ffffff;
    color: black !important;
    display: flex;
    font-family: 'Poppins';
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 31;
    border-radius: 5px;
}
.GamerulescontainerTP ul li {
    padding: 17px;
}
.GamerulescontainerTP {
    overflow-y: auto; /* Enables vertical scrolling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
}

/* Webkit Browsers (Chrome, Safari, Edge) */
.GamerulescontainerTP::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

/* Track (background of scrollbar) */
.GamerulescontainerTP::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
    border-radius: 10px; /* Rounded edges */
}

/* Handle (the draggable part) */
.GamerulescontainerTP::-webkit-scrollbar-thumb {
    background: #888; /* Darker grey */
    border-radius: 10px;
}

/* Handle on hover */
.GamerulescontainerTP::-webkit-scrollbar-thumb:hover {
    background: #555; /* Even darker grey */
}

/* .gameruleMainHeadingTP {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color: black;
    
} */
.gameruleMainHeadingTP {
    position: fixed;
    top: 15px;
    font-size: 53px;
    color: #000;
    background: aliceblue;
    width: 49.3%;
    text-align: center;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px #bfcacc;
}
.unorderedlistitemsTP {
    /* margin-top: 2817px; */
    position: absolute;
    top: 100px;
    left: 50px;
}


.GamerulescontainerportTP {
    position: absolute;
    height: 1362px;
    width: 59%;
    background: #ffffff;
    color: black !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 110px;
    right: 0;
    top: 210px;
    margin: auto;
    z-index: 31;
}
.GamerulescontainerportTP ul li {
    padding: 17px;
}

/* .gameruleMainHeadingportTP {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color: black;
} */
.gameruleMainHeadingportTP {
    position: fixed;
    top: 208px;
    font-size: 53px;
    color: #000;
    background: aliceblue;
    width: 59%;
    text-align: center;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px #bfcacc;
}
.unorderedlistitemsportTP {
    /* margin-top: 2817px; */
    position: absolute;
    top: 100px;
}
.GamerulescontainerlndTP {
    position: absolute;
    height: 1221px;
    width: 32%;
    background: #ffffff;
    color: black !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 31;
}
.GamerulescontainerlndTP ul li {
    padding: 17px;
}

/* .gameruleMainHeadinglndTP {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color: black;
} */
.gameruleMainHeadinglndTP {
    position: fixed;
    top: 14px;
    font-size: 53px;
    color: #000;
    background: aliceblue;
    width: 32%;
    text-align: center;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px #bfcacc;
}
.unorderedlistitemslndTP {
    /* margin-top: 2817px; */
    position: absolute;
    top: 100px;
}

#rankinginfoTP{
    text-align: center;
}
#rankinginfodelerTP{
    text-align: center;
}

/* .waviy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(111, 96, 153, 0.6));
    text-align: center;
    margin-top: -220px;
    white-space: nowrap;
    
  }
  .titleanim{
    position: absolute;
  }
  
  .waviy img {
    position: relative;
    top: 21px;
    right: 0;
    left: 0;
    margin: auto;
    display: inline-block;
    width: 46px;
    height: 90px;
    animation: waviy 1.5s infinite;
    animation-delay: calc(0.1s * var(--i));
  }
  
  @keyframes waviy {
    0%, 40%, 100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-30px);
    }
  }        */


  .cardDeckPortraitTP{
    position: absolute;
    left: 37%;
    margin-top: 5%;
  }

  .cardDeckLandscapeTP{
    /* position: absolute;
    left: 80%;
    margin-top: 3%; */
    position: absolute;
    left: 43%;
    right: 0;
    margin-top: 0%;
    text-align: center;
   width: 100%;
   max-width: 100px;
  }
  
    
  .infotext_windowsTP {
    position: relative;
    top: 818px;
    right: 0px;
    width: 441px;
    height: 266px;
    border: 5px solid slategrey;
    /* border-image: linear-gradient(to right, #a29585, #976565, #296790, #403838); */
    border-image-slice: 1;
    border-radius: 16px;
}


.animate-charcter{
   text-transform: uppercase;
  background-image:linear-gradient(230deg, #f9f9f9 0%, #ffffff 29%, #4edcf1 67%, #f9ffff 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 150% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 50px;
}

@keyframes textclip {
  to {
    background-position: 250% center;
  }
}

.icon_opacity {
    cursor: no-drop;
    opacity: 0.4;
}


.head_text {
    background: linear-gradient(
      to top,
      #020e1c,
      #179ac6);
    background-clip: text;
    color: transparent;
    font-size: 30px;
}


.showPayoutTP {
    position: absolute;
    top: 303px;
    right: 7px;
    width: 100%;    
    max-width: 453px;
    /* height: 601px; */
    z-index: 6;
    /* background: linear-gradient(#14a3d7, rgb(4, 3, 1), #000000); */
    box-shadow: rgb(208, 208, 208) 6px 3px 6px 0px inset, #34a5ff -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 20px;
    white-space: nowrap;
    transform-origin: top;
    animation: payouts12 1s ease-in-out forwards;
}

@keyframes payouts12 {
    0% {
        transform: scaleY(0); 
        opacity: 0;
    }
    50%{
        transform: scaleX(0); 
        opacity: 0.5;

    }
    100% {
        transform: scaleY(1); 
        opacity: 1;
    }
}

.showPayoutTP {
    background: linear-gradient(237deg, #185361f7, #122d31, #206966, #152e3dfc);
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.payout_winsow_TP {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.payoutheadingTP {
    font-size: 40px;
    font-family: 'Poppins';
    color: black;
    
}

.payout_bodyTP {
    width: 100%;
    font-size: 27px;
    color: white;
    align-items: center;
    margin: auto;
}
.payoutheadingTP {
    font-size: 40px;
    font-family: 'Poppins';
    box-shadow: 0px 0px 5px 0px #f1ebeb4f;
    border-radius: 6px;
}

.showpayoutwndsThead{
    font-size: 30px;
    text-align: left;
    background: linear-gradient(to top, #df9a0c, #f7dc06);
      background-clip: text;
      color: transparent;
}

.bet_bonusBTN{
    font-size: 30px;
    color: aliceblue;
    font-weight: 600;
    font-family: 'Poppins';
    width: 100%;
    box-shadow: 0px 0px 4px 0px #746d6d;
    border-radius: 6px;
    background: #00000057;
    width: 100%;
    margin: 5px;
}
.bet_bonusBTN:hover{
    background: linear-gradient(45deg, #e3a816, #2d230c94);
}
.bet_bonusBTN:active{
    background: linear-gradient(45deg, #e3a816, #2d230c94);
}
.dis_flex_evn{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.PayoutBtnBGC{
    background: linear-gradient(45deg, #e3a816, #2d230c94);
}




/* potraite view start   */

.showPayoutTPprtrt {
    position: absolute;
    top: 1575px;
    left: 70px;
    width: 100%;
    max-width: 470px;
    /* height: 601px; */
    z-index: 6;
    /* background: linear-gradient(#14a3d7, rgb(4, 3, 1), #000000); */
    box-shadow: rgb(208, 208, 208) 6px 3px 6px 0px inset, #34a5ff -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 20px;
    white-space: nowrap;
    transform-origin: top;
    animation: payouts 1s ease-in-out forwards;
}

@keyframes payouts {
    0% {
        transform: scaleY(0); 
        opacity: 0;
    }
    50%{
        transform: scaleX(0); 
        opacity: 0.5;

    }
    100% {
        transform: scaleY(1); 
        opacity: 1;
    }
}
.showPayoutTPprtrt {
    background: linear-gradient(237deg, #185361f7, #122d31, #206966, #152e3dfc);
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* Portraire view end   */






/* landscape view start   */
.showPayoutLandscpeTP {
    position: absolute;
    top: 520px;
    left: 177px;
    width: 100%;
    max-width: 470px;
    /* height: 601px; */
    z-index: 6;
    /* background: linear-gradient(#14a3d7, rgb(4, 3, 1), #000000); */
    box-shadow: rgb(208, 208, 208) 6px 3px 6px 0px inset, #34a5ff -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 20px;
    white-space: nowrap;
    transform-origin: top;
    animation: payouts 1s ease-in-out forwards;
}

@keyframes payouts {
    0% {
        transform: scaleY(0); 
        opacity: 0;
    }
    50%{
        transform: scaleX(0); 
        opacity: 0.5;

    }
    100% {
        transform: scaleY(1); 
        opacity: 1;
    }
}
.showPayoutLandscpeTP {
    background: linear-gradient(237deg, #185361f7, #122d31, #206966, #152e3dfc);
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* landscape view end   */



