  #jacksorBetterBgContainer{
    /* font-family: "Roboto"; */
    font-family: sans-serif;
    font-weight: 600;
    color: #060606;
  }

#jacksorBetterBgContainer button:active{
    scale: 0.8;
    opacity: 1;
  }

  
#jacksorBetterBgContainer button:disabled{
    scale: 1;
    opacity: 0.5;
  }
  
  .betButtonImagesPrt{
    width: 100%;
  }

  .autoholdButton{
    position: absolute;
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 5%;
    height: 8%;
    bottom: 3%;
    left: 3%;
    background-image: linear-gradient(to left, #094d57, #00abb4, #094d57);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoholdButtonPrt{
    position: absolute;
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 16%;
    height: 8%;
    bottom: 3%;
    left: 3%;
    background-image: linear-gradient(to left, #094d57, #00abb4, #094d57);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoholdButtonLnd{
    position: absolute;
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 8%;
    height: 12%;
    bottom: 3%;
    left: 3%;
    background-image: linear-gradient(to left, #094d57, #00abb4, #094d57);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoplayButton{
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 5%;
    height: 8%;
    position: absolute;
    bottom: 3%;
    left: 9%;
    background-image: linear-gradient(to left, #e30a3a, #d52e53e3, #e30a3a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoplayButtonPrt{
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 16%;
    height: 8%;
    position: absolute;
    bottom: 3%;
    right: 3%;
    background-image: linear-gradient(to left, #e30a3a, #d52e53e3, #e30a3a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoplayButtonLnd{
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 8%;
    height: 12%;
    position: absolute;
    bottom: 3%;
    right: 3%;
    background-image: linear-gradient(to left, #e30a3a, #d52e53e3, #e30a3a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* .autoplayButton{
    animation: blink normal 1s infinite ease-in-out;
  } */

  .borderGlow{
    animation: borderBlinkJs 1s infinite cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0px 0px 40px #ff8d00f5;
  }

  .tableContainer tbody div{
    position: absolute;
    width: 106%;
    height: 12%;
    left: -3%;
    border-radius: 50px;
    z-index: 50;

  }

  .glowPayoutInfo{
    animation: borderBlinkJs 0.8s infinite cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0px 0px 40px #ff8d00f5;
    border:6px solid transparent;
  }
  @keyframes borderBlinkJs {
    0% {
      border:6px solid rgb(255, 60, 0);
      
    }
    50% {
      border:6px solid rgba(214, 138, 23, 0);
      /* border: transparent; */
    }
    100% {
      border: 6px solid rgb(255, 255, 0);
    }
  }
  
  .chipTextGlow{
    /* animation: chipTextBlink 1s infinite ease-in-out; */
    animation: chipTextBlink 1s infinite cubic-bezier(0, 0, 0.2, 1) ;
  }
  @keyframes chipTextBlink {
    0% {
      color: rgb(255, 255, 0);
      
    }
    50% {
      border-color:rgb(255, 255, 0);
    }
    100% {
      color: rgb(255, 255, 0);
    }
  }

  .payoutContainer{
    position: absolute;
    color: aliceblue;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 60%;
    /* width: 80%; */
    top: 6%;
    z-index: 3;
    line-height: 1;
  }

  .payoutContainerPrt{
    position: absolute;
    color: aliceblue;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 70%;
    /* width: 80%; */
    top: 22%;
    /* top: 8%; */
    z-index: 3;
    line-height: 1;
  }
  .payoutContainerLnd{
    position: absolute;
    color: aliceblue;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 60%;
    /* width: 65%; */
    top: 8%;
    z-index: 3;
    line-height: 1;
  }


  .tableContainer{
    width: 100%;
    font-size: 30px;
    /* font-size: 25px; */
  }

  /* table tr:hover {
    border: 2px solid orange;
    /* background-color: #f0f0f0; */
  /* } */ */

  #jacksorbetterTable table {
    border-collapse: collapse;
    border-spacing: 5;
  }
#jacksorbetterTable table tr td{
  padding: 3px;
  padding-right: 0.5%;
  /* text-align: center; */
  text-align: right;
}
#jacksorbetterTable table tbody tr td:first-child{
  text-align: left;
  text-transform: uppercase;
}


/* #jacksorbetterTable table tbody tr td:nth-child(2){
  text-align: left;
  text-transform: uppercase;
  color: rgb(215, 253, 0);
} */

/* .tablerow0{
  background-image: linear-gradient(45deg, #424243,#3e1881, #180834);
  border-radius: 10px;
  
}
.tablerow1{
  background-image: linear-gradient(45deg, #62686d,#2e6dd177, #2a2749);
  
}
.tablerow2{
  background-image: linear-gradient(45deg, #62686d,#23c574e3, #035458);
  
}
.tablerow3{
  background-image: linear-gradient(45deg, #62686d,#1cd3cae3, #007454);
  
}
.tablerow4{
  background-image: linear-gradient(45deg, #62686d,#925f859f, #00c588);
  
}
.tablerow5{
  background-image: linear-gradient(45deg, #62686d,#c5236fe3, #a2dc76);
  
}
.tablerow6{
  background-image: linear-gradient(45deg, #62686d,#9ac523e3, #f9c160);
  
}
.tablerow7{
  background-image: linear-gradient(45deg, #62686d,#c57a23e3, #ee6c43);
  
}
.tablerow8{
  background-image: linear-gradient(45deg, #62686d,#d34040e3, #ee0049);

} */



/* .tablerow0 {
  background-image: linear-gradient(45deg, #3b3b3b, #5a5a5a, #3a3a3a);
  border-radius: 10px;
}

.tablerow1 {
  background-image: linear-gradient(45deg, #4a4a4a, #6d83d1, #3b3f67);
}

.tablerow2 {
  background-image: linear-gradient(45deg, #4e4e4e, #33c77a, #207a5c);
}

.tablerow3 {
  background-image: linear-gradient(45deg, #515151, #1dc6c3, #0b8285);
}

.tablerow4 {
  background-image: linear-gradient(45deg, #545454, #b071b0, #774a77);
}

.tablerow5 {
  background-image: linear-gradient(45deg, #575757, #d45a88, #b9475f);
}

.tablerow6 {
  background-image: linear-gradient(45deg, #5a5a5a, #e8b23f, #c7841b);
}

.tablerow7 {
  background-image: linear-gradient(45deg, #5d5d5d, #ff8741, #d4632c);
}

.tablerow8 {
  background-image: linear-gradient(45deg, #606060, #ff5252, #c90028);
} */


.tablerow0, .tablerow1,.tablerow2,.tablerow3,.tablerow4,.tablerow5,.tablerow6,.tablerow7,.tablerow8,.tablerow9{
  /* background-color: rgba(96, 183, 209, 0.37); */
  /* background: rgba(221, 113, 113, 0.301); */
  /* background: rgb(221 184 113 / 48%); */
  background: #ac8431bf;
  position: relative;
}
.errorPopupContainerwindows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 35%;
  height: 296px;
  background: hsl(0deg 0% 97% / 82%);
  z-index: 500;
  box-shadow: 20px 20px 5px #0c0303;
  border-radius: 30px;
  /* visibility: visible;
  opacity: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorPopupContainerPrt {
  width: 60%;
 
}

.popupbetshighPortrait{
  position: absolute;
  top: 780px;
  left: 145px;
  width: 900px;
  height: 450px;
  background: hsl(0deg 0% 97% / 82%);
  z-index: 1500;
  box-shadow: 20px 20px 5px #0c0303;
  border-radius: 30px;
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupbetshighLndsp{
  position: absolute;
  top: 400px;
  right: 0;
  left: 0;
  margin: auto;
  width: 900px;
  height: 450px;
  background: hsl(0deg 0% 97% / 82%);
  z-index: 1500;
  box-shadow: 20px 20px 5px #0c0303;
  border-radius: 30px;
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}

.errorPopupExitIcon{
  position: absolute;
  right: 2%;
  top: 2%;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 100;
}
.errorPopupTitle{
  position: relative;
  right: 2%;
  top: 6%;
  font-size: 40px;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 100;
  /* margin-bottom: 6%; */
  margin-bottom: 5%;
}

.errorPopupInfo{
  position: relative;
  font-size: 40px;
  color: #230303;
  font-weight: bold;
  /* font-family: "Poppins"; */
  text-align: center;
  line-height: 1;
  /* top: 14%; */
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverContainer{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #04040487;
  z-index: 20;
}
.betChipscoverContainer{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #d5ebe400;
  z-index: 20;
}

.autoLimitContainer{
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.autoLimitContainer button  {
  font-size: 40px;
  border: 2px solid #ff000052;
  padding: 4%;
  border-radius: 100px;
  margin-bottom: 10%;
}
.autoplayContinue {
  font-size: 50px;
  border: 2px solid #ff000052;
  padding: 1%;
  border-radius: 100px;
  width: 40%;
  background-color: #00b46fa1;
  color: #0c0303;
}
.autoLimitContainer button:first-child {
  background-color: #00abb471;
}
.autoLimitContainer button:last-child{
  background-color: #00b46fa1;
}

.menuOptionContainer{
  position: relative;
  background: azure;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10%; */
  border-radius: 100px;
  height: 40%;
  top: 10%;
  left: 1%;
  z-index: 21;
}
.menuOptionContainerPrt{

  position: relative;
  background: azure;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10%; */
  border-radius: 50px;
  height: 15%;
  top: 6%;
  left: 1%;
  z-index: 21;
}

.menuOptionContainer button{
  padding: 10px;
  border-radius: 46%;
  color: #ffffff;
  height: 20%;
  font-size: 40px;
  width: 82%;
}
.menuOptionContainerPrt button{
  padding: 10px;
  border-radius: 46%;
  color: #ffffff;
  height: 30%;
  font-size: 40px;
  width: 90%;
  margin-bottom: 10%;
}

.menuOptionContainer button:first-child{
  margin-bottom: 20px;
  
  
}

.menuOptionContainer button:last-child{
  
}

.borderColor{
  border: 2px solid #131D50;
  color: #131D50 !important;

}
.backgroundColor{
  background-image: linear-gradient(#040610, #131D50, #0E1E33);
}


.helpMainContainer{
  border: 2px solid #3c45be;
  width: 80%;
  position: relative;
  left: 8%;
  /* height: 70%; */
  max-height: 70%;
  min-height: 50%;
  height: fit-content;
  top: -30%;
  bottom: 0;
  margin: auto;
  overflow: auto;
  font-size: 40px;
  color: #ffffff;
  line-height: 1.5;
  background-image: linear-gradient(#040610, #131D50, #0E1E33);
  border-radius: 25px;
  padding: 2%;
  z-index: 21;
}
.helpMainContainerPrt{

  border: 2px solid #3c45be;
  width: 95%;
  position: relative;
  left: 0%;
  /* height: 40%; */
  max-height: 50%;
  min-height: 26%;
  height: fit-content;
  top: 8%;
  bottom: 0;
  margin: auto;
  overflow: auto;
  font-size: 40px;
  color: #ffffff;
  line-height: 1.5;
  background-image: linear-gradient(#040610, #131D50, #0E1E33);
  border-radius: 25px;
  padding: 2%;
  z-index: 21;
  /* white-space: nowrap; */
  overflow-x: auto;
  cursor: none;

}

#GameHelpContent ol,#GameHelpContent p{
  margin: 0 0 0 50px;

}

.helpMainContainer table ,.helpMainContainerPrt table{
  border-collapse: separate;
  border-spacing: 10px;
  width: 100%;
  height: auto;
  font-size: 34px;
  /* font-family: 'Poppins'; */
  margin: 10px 0;
  line-height: 1 !important;
  white-space: nowrap;
}

.helpMainContainer tbody,.helpMainContainerPrt tbody{
  width: 100%;
  text-align: center;
  font-size: 34px;
  color: white;
  /* font-family: 'Poppins'; */
  position: relative;
  top: 50px;
}

.tableContainer tbody td{
text-align: center;
}


.tableContainer tbody tr::before {
  content: "";
  position: absolute;
  left: -2.8%;
  /* top: 6%;
  height: 82%; */
  /* height: 10%; */
  height: 100%;
  width: 3%;
  background:#ac8431bf;
  border-radius: 50% 0% 0% 50%;
}

.tableContainer tbody tr::after {
  content: "";
  position: absolute;
  left: 99.8%;
  /* height: 10%; */
  height: 100%;
  /* height: 82%; */
  /* top: 6%; */
  width: 3%;
  background: #ac8431bf;
  border-radius: 0% 50% 50% 0%;
}

.winPopupForJrbtIntl{
  visibility: hidden;
  opacity: 0;
}

.winPopupJrb {
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  width: 100%;
  max-width: 400px;
  height: 120px;
  border: solid 6px yellow;
  border-radius: 50px;
  /* animation: blink normal 1s infinite ease-in-out; */
  animation: borderBlink 0.3s infinite ease-in-out;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  
}

.winPopupJrb_deucesWild{
  position: absolute;
  top: 4%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  width: 100%;
  max-width: 400px;
  height: 120px;
  border: solid 6px yellow;
  border-radius: 50px;
  /* animation: blink normal 1s infinite ease-in-out; */
  animation: borderBlink 0.3s infinite ease-in-out;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}


@keyframes borderBlink {
  0% {
      border-color: yellow;
  }
  50% {
      border-color: transparent;
  }
  100% {
      border-color: yellow;
  }
}

.winamtMpJrb{
  color: #ffffff;
  font-size: 40px;
  text-align: center;
}

.payOutHand{
  position: absolute;
  left: 0%;
  right: 0%;
  margin: auto;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: linear-gradient(45deg, #ff6f61, #ffba08);
  /* background: linear-gradient(45deg, #ffba08, #ff6f61); */
  /* background: radial-gradient(circle, #42a5f5, #1e88e5, #1565c0); */
  /* background: linear-gradient(to right, #ff6f61, #ffba08, #6bcbef, #9c27b0); */
  /* border-image-source: linear-gradient(45deg, #42a5f5, #ff6f61); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
  min-width: 400px;
  width: fit-content;
  border-radius: 50px;
  padding: 14px;
}
.payOutHandWds{
  top: 75%;
}
.payOutHandPrt{
  top: 75%;
}
.payOutHandLnd{
  top: 82%;
}

.autoplayactiveRoundJrbWds{
  bottom: 8%;
  left: 1%;
}
.autoplayactiveRoundJrbPrt{
  bottom: 1%;
  right: 16%;
}
.autoplayactiveRoundJrbLnd{
  bottom: 11%;
  right: 1%;
}

.autoplayactiveRound{
  position: absolute;
  font-size: 35px;
  color: azure;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 5px solid red;
  animation: rainbowBorder 1s infinite linear;

}

@keyframes rainbowBorder {
  0% {
    border-color: red;
    box-shadow: 0 0 10px red;
  }
  25% {
    border-color: orange;
    box-shadow: 0 0 8px orange;
  }
  50% {
    border-color: yellow;
    box-shadow: 0 0 5px yellow;
  }
  75% {
    border-color: green;
    box-shadow: 0 0 8px green;
  }
  100% {
    border-color: red;
    box-shadow: 0 0 10px red;
  }
}



/* Portrait Mode: Mobile Devices Only */
/* @media only screen and (max-width: 767px) and (orientation: portrait) {
  #jacksorBetterBgContainer button{
    cursor: none;
  }
} */

/* Landscape Mode: Mobile Devices Only */
/* @media only screen and (max-width: 767px) and (orientation: landscape) {
  #jacksorBetterBgContainer button{
    cursor: none;
  }

} */

/* 
.rebet2button{
  overflow-wrap: anywhere;
  width: 75% !important;
} */

.min-max-bet-limits{
  position: absolute;
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  color: #fff;
  top: 13%;
  left: 3%;
  font-size: 34px;
  

}

.min-max-bet-limits-landscape{
  top: 18%;
}