/* .bacaratbg{
   
    background-image: url("../bacaratassets/BG.png");
    width: 100%;
    height: 100vh;
} */
/* 
 @media screen and (orientation: landscape) {
    #userpannel {
      
            position: absolute;
            background: red;

            top: 5px;
            left: 15px;
         
        
    }
#handid{
    background: rgb(128, 0, 43);

}
#plyerid{
    background: rgb(0, 51, 128);
    
}

}
.bcrat{
    width: 100%;
}

@media screen and (orientation: portrait) {
    #userpannel {
        position: absolute;
        background: green;
        top: 5px;
        left: 146px;
      
    }
#handid{
    background: rgb(128, 119, 0);
    
}
#plyerid{
    background: green;

}


} 

#userpannel.portrait {
    position: absolute;
    background: green;
    top: 5px;
    left: 146px;
}

#userpannel.landscape {
    position: absolute;
    background: green;
    top: 5px;
    left: 146px;
} */


/* @media screen and (min-width: 785px) {
    #inforussian {
        background: red;
    }
} */

button{
    background-color: transparent;
    border: none;
    outline: none;
}
.betincrement {
    position: absolute;
    top: 45% !important;
    right: 47.5% !important;
    color: yellow !important;
    font-size: 70px !important;
    font-family: 'Poppins' !important;

}

.antetextportraite {
    position: absolute;
    top: 45.5%;
    right: 46%;
    /* transform: scale(2.6,0.5); */
    color: yellow;
    font-size: 46px;
    font-family: "Poppins";
    /* left: 42%; */
    pointer-events: none;
    z-index: 1;
    width: 14%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.antetextwindows {
    position: absolute;
    top: 43%;
    /* right: 48.5%; */
    color: yellow;
    font-size: 27px;
    font-family: 'Poppins';
    z-index: 1;
    height: 141px;
    width: 118px;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    align-items: center;
    /* text-align: center; */
    left: 0;
    right: 0;
    font-weight: bolder;
    margin: auto;
    pointer-events: none;
}

.antetextlnspe {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    position: absolute;
    width: 20%;
    height: 20%;
    top: 40%;
    right: 34%;
    color: yellow;
    font-size: 39px;
    font-family: 'Poppins';
    pointer-events: none;
}

.useridlandscape {
    position: absolute;
    bottom: 4.5%;
    right: 8%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
}

.useridwindows {
    position: absolute;
    top: 0.5%;
    left: 9%;
    /* right: 6%; */
    color: #f7f812;
    font-size: 44px;
    font-family: 'Poppins';
    z-index: 1;
}

.useridportraite {
    position: absolute;
    top: 8.5%;
    left: 10%;
    /* transform: scale(1.7,0.7); */
    /* right: 72%; */
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
}

.balancewindows {
    position: absolute;
    top: 0.5%;
    left: 28%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}

.balanceportraite {
    position: absolute;
    /* transform: translate(179px,709px); */
    /* top: 0%;
    left: 28%; */
    /* transform: scale(1.5,0.6); */
    top: 4%;
    left: 20%;
    color: #fffe00;
    font-size: 42px;
    font-family: 'Poppins';
    font-weight: bold;
}

.balancelndscape {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 3%;
    left: 15%;
    color: #fffe00;
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: bold;
}

.betsportraite {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 4%;
    /* left: 64%; */
    left: 62%;
    /* transform: scale(2.5,0.55); */
    color: #fffe00;
    font-size: 42px;
    font-family: 'Poppins';
    font-weight: bold;
}

.betslandspe {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 13%;
    left: 8%;
    color: #fffe00;
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: bold;
}

.betswindows {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 0.3%;
    left: 49%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}
.winswindows {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 1%;
    /* right: 23.7%; */
    /* background: red; */
    left: 75%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
    /* width: 133px; */
    /* height: 51px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.winsportraite {
    position: absolute;
    top: 4%;
    right: 8%;
    /* right: 20%; */
    color: #fffe00;
    /* transform: scale(2.5,0.55); */
    font-size: 42px;
    width: 10%;
    /* height: 13%; */
    font-family: 'Poppins';
    font-weight: bold;
}

.winslandspe {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 4%;
    left: 31%;
    /* right: 12%; */
    color: #fffe00;
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: bold;
}

.drawtextportraite {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 83%;
    left: 46%;
    /* transform: scale(1.4,0.5); */
    color: white;
    font-size: 47px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}

.buytextportraite {
    position: absolute;
    /* transform: translate(272px,699px); */
    /* transform: scale(1.4,0.5); */
    color: white;
    font-size: 47px;
    top: 81%;
    left: 68%;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}

.betportraite {
    /* position: absolute;
    color: white;
    font-size: 40px;
    top: 75.5%;
    left: 76.5%;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 2; */
   

    position: absolute;
    top: 73.5%;
    left : 74%;
    color: #fffcfc;
    font-size: 40px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 10;
    width: 11%;
    height:6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    pointer-events: none; 
    
}

.foldtextportraite {
    position: absolute;
    color: white;
    font-size: 40px;
    top: 66.5%;
    left: 83%;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 10;
    pointer-events: none;
    width: 11%;
    height: 6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.topcards {
    /* background: red; */
    width: 51%;
    position: absolute;
    top: 20%;
    left: 24%;
    height: 10%;
}

.betpannelrussian {
    /* background: yellow; */
    position: absolute;
    top: 2%;
    left: 0%;
    width: 100%;
    height: 89%;
}

.drawtextlandspe {
    position: absolute;
    top: 91%;
    left: 64%;
    /* transform: translate(639px,367px); */
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 3;
}

.buytextlndspe {
    position: absolute;
    top: 85%;
    left: 73%;
    /* transform: translate(721px,337px); */
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 3;
}

.betlndspe {
    position: absolute;
    top: 76%;
    left: 79%;
    /* transform: translate(763px,271px); */
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 3;
}

.foldtextlndspe {
    position: absolute;
    top: 61%;
    left: 82.5%;
    /* transform: translate(758px,195px); */
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 3;
}
.drawtextwindows {
    position: absolute;
    top: 86%;
    left: 48.3%;
    color: white !important;
    font-size: 35px;
    font-family: 'monospace';
    font-weight: bold;
    pointer-events: none;
    z-index: 1;
    width: 143px;
    text-align: center;
    /* background: blue; */
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}

.drawtextPortrait{
    position: absolute;
    top: 81%;
    left: 45.5%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 10%;
    height: 5%;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.drawtextLndsp {
    position: absolute;
    top: 87%;
    left: 69%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 6%;
    height: 8%;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}
.buytextwindows {
    position: absolute;
    top: 83.7%;
    left: 58.6%;
    /* transform: translate(759px,571px); */
    color: white !important;
    font-size: 31px;
    font-family: 'monospace';
    font-weight: bold;
    pointer-events: none;
    z-index: 1;
    width: 132px;
    height: 107px;
    text-align: center;
    /* background: #768b00; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}
.buytextPortrait{
    position: absolute;
    top: 79%;
    left: 61%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    /* width: 99px; */
    text-align: center;
    width: 11%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-wrap: anywhere;
    align-items: center;
    flex-wrap: wrap;

}

.buytextLndsp{
    position: absolute;
    top: 77%;
    left: 77%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    width: 6%;
    height: 8%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-wrap: anywhere;
    align-items: center;
    flex-wrap: wrap;

}
.betwindows_rebet {
    position: absolute;
    top: 75.5%;
    left: 66.4%;
    color: #fff;
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 700;
    z-index: 2;
    /* background: red; */
    pointer-events: none;
    width: 138px;
    height: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}
.betwindows {
    position: absolute;
    top: 75.5%;
    left: 66.4%;
    color: #fff;
    font-size: 35px;
    font-family: monospace;
    font-weight: 700;
    z-index: 2;
    /* background: red; */
    pointer-events: none;
    width: 138px;
    height: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}
.betportrait{
    position: absolute;
    top: 73.5%;
    left: 74%;
    color: #fffcfc;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 11%;
    height: 6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    line-height: 1;
}

.betLandscape {
    position: absolute;
    top: 63.5%;
    left: 82.5%;
    color: #fffcfc;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 6%;
    height: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    line-height: 1;
}
.foldtextwindows {
    position: absolute;
    top: 62%;
    left: 74%;
    font-size: 35px;
    font-family: monospace;
    font-weight: 700;
    color: #ffffff !important;
    z-index: 1;
    /* background: red; */
    width: 128px;
    height: 128px;
    text-align: center;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.winPopupForMptIntl{
    visibility: hidden;
    opacity: 0;
}

.winPopupForMptSw{
    position: absolute;
    top: 1930px;
    left: 4%;
    width: 17%;
    height: 69px;
    border: solid 6px yellow;
    border-radius: 50px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* animation: blink normal 1s infinite ease-in-out; */
    animation: borderBlink 0.3s infinite ease-in-out;

}



.winPopupForMLsp {
    position: absolute;
    top: 460px;
    left: 76.5%;
    width: 8%;
    height: 69px;
    border: solid 6px yellow;
    border-radius: 50px;
    /* animation: blink normal 1s infinite ease-in-out; */
    animation: borderBlink 0.3s infinite ease-in-out;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    
}



.winpopup_russian {
    position: absolute;
    /* margin-top: 36%; */
    top: 769px;
    left: 58%;
    width: 278px;
    height: 104px;
    border: solid 8px yellow;
    border-radius: 50px;
    animation: borderBlink 0.4s infinite ease-in-out;
    /* border: 5px yellow; */
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
/* Keyframes for blinking border */
@keyframes borderBlink {
    0% {
        border-color: yellow;
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: yellow;
    }
}
.popupbetshigh {
    position: relative;
    top: 436px;
    left: 781px;
    width: 764px;
    height: 257px;
    background: hsl(282.94deg 12.16% 78.65%);
    z-index: 79;
    box-shadow: 8px 14px #0c0303;
    border-radius: 30px;
}

.popupbetshighMobileIntial{
    visibility: hidden;
    opacity: 0;
    scale: 0;
    

}
.popupbetshighwindows {
    position: relative;
    top: 424px;
    left: 732px;
    width: 878px;
    height: 319px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.poptext_betshigh {
    position: absolute;
    /* top: 12px; */
    padding: 94px 21px;
    font-size: 30px;
    color: #230303;
    font-weight: bold;
    font-family: 'Poppins';
}

.poptextBetshighwindows{
    position: relative;
    /* top: 12px; */
    padding: 10px 77px;
    font-size: 40px;
    color: #230303;
    font-weight: bold;
    font-family: 'Poppins';
    text-align: center;
}

.popupbetshighwindows {
    position: relative;
    top: 188px;
    left: 728px;
    width: 900px;
    height: 296px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popupbetshighPortrait{
    position: absolute;
    top: 780px;
    left: 145px;
    width: 900px;
    /* height: 400px; */
    height: 450px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 1500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popupbetshighLndsp{
    position: absolute;
    top: 400px;
    right: 0;
    left: 0;
    /* bottom: 0; */
    margin: auto;
    width: 900px;
    /* height: 400px; */
    height: 450px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 1500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
}

.poptext_betshigh {
    position: absolute;
    /* top: 12px; */
    text-align: center;
    padding: 77px 21px;
    font-size: 37px;
    color: #230303;
    font-weight: bold;
    font-family: 'Poppins';
}

.poptextBetshighPortrait{
    position: relative;
    /* top: 12px; */
    padding: 10px 77px;
    font-size: 50px;
    color: #230303;
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
    line-height: 1;
}




.poptextante_betshigh{
    position: absolute;
    padding: 88px 37px;
    font-size: 35px;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Poppins';

}
.attention_text {
    position: absolute;
    top: -5px;
    /* margin-left: 132px; */
    text-align: center;
    width: 100%;
    /* background: red; */
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    padding: -6px 167px;
    font-size: 65px;
    color: #1d0303;
    font-weight: bold;
    font-family: 'Poppins';
}

.attentionTextPortrait{
    position: relative;
    font-size: 80px;
    color: #1d0303;
    font-weight: bold;
    font-family: "Poppins";
    margin-bottom: 6%;
}

.chipglow_bonus {
    position: absolute;
    top: 693px;
    /* margin-bottom: 74%; */
    /* margin-top: 831.5px; */
    left: 1618px;
    width: 131px;
    height: 131px;
    border-radius: 50%;
    z-index: 1;
    border: 10px solid yellow;
    animation: blink normal 0.5s infinite ease-in-out;
}
.chipglow_russian {
    position: absolute;
    top: 754px;
    /* margin-bottom: 74%; */
    /* margin-top: 831.5px; */
    left: 1434px;
    width: 131px;
    height: 131px;
    border-radius: 50%;
    z-index: 1;
    border: 10px solid yellow;
    animation: blink normal 0.5s infinite ease-in-out;
}
.drawChipDiv{
    position: absolute;
    width: 12%;
    /* bottom: 5%; */
    /* left: 44%; */
    z-index: -6;
    transform: translate(190px, 1200px);
}

.drawChipDivLndsp {
   
    position: absolute;
    width: 6%;
    right: 2%;
    top: 48.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.winamt_russian {
    position: absolute;
    margin-top: 1%;
    /* top: 5%; */
    left: 1%;
    color: #ffffff;
    font-size: 37px;
    height: 58px;
    width: 258px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: red; */
    text-align: center;
}
.winamtMpSw{
    color: #ffffff;
    font-size: 40px;
    text-align: center;
}



.betchipsaddtodraw{
    position: absolute;
    width: 100%;
    /* bottom: 5%; */
    /* left: 44%; */
    z-index: 3;
}
.betTextaddtodraw{
    width: 100%;
    display: flex;
    color: white;
    justify-content: center;
    z-index: 3;
    font-size: 38px;
    position: relative;
    left: -3%;
    top: 50px;
    /* font-family: 'Poppins'; */
    font-family: 'Poppins';
    font-weight: bold;
}
.betTextaddwLndsp {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 3;
    font-size: 38px;
    position: relative;
    /* font-family: 'Poppins'; */
    font-family: 'Poppins';
    font-weight: bold;
}



.foldtextportrait{
    position: absolute;
    top: 66.5%;
    left: 83%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11%;
    height: 6%;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

}
.foldtextLandscape{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 84.5%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 8%;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    line-height: 1;

}

.rebettextwindows {
    position: absolute;
    top: 66%;
    left: 71.6%;
    color: white;
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
    width: 105px;
    text-align: center;
    overflow-wrap: anywhere;
    /* background: red; */
}
.rebettextportrait{    
    position: absolute;
    top: 66.5%;
    /* left: 83%; */
    left: 83.5%;
    color: #fffcfc;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    /* width: 11%; */
    width: 10%;
    height: 6%;
    text-align: center;
    overflow-wrap: anywhere;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;

}
.rebettextwindows {
    position: absolute;
    top: 63%;
    left: 74.3%;
    color: white;
    font-size: 31px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 23;
    width: 105px;
    text-align: center;
    display: flex;
    overflow-wrap: anywhere;
    /* background: red; */
    width: 117px;
    height: 128px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.royalflshwindows {
    position: absolute;
    top: 27%;
    left: 29.7%;
    /* transform: translate(363px,126px); */
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.strightflushwindows {
    position: absolute;
    top: 31%;
    left: 28.8%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fourofkindwindows {
    position: absolute;
    top: 35%;
    left: 29.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fullhouse {
    position: absolute;
    top: 39%;
    left: 31.7%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.flush {
    position: absolute;
    top: 43%;
    /* left: 37.9%; */
    left: 34.4%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.strightwindows {
    position: absolute;
    top: 47%;
    right: 59%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.threekind {
    position: absolute;
    top: 51%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.twopair {
    position: absolute;
    top: 55%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.onepair {
    position: absolute;
    top: 59%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.aceking {
    position: absolute;
    top: 63%;
    right: 59.1%;
    font-size: 27px;
    color: #a1a1a1;
    font-family: 'Poppins';
}
.insurencepays {
    position: absolute;
    top: 18.7%;
    left: 44.2%;
    /* transform: translate(613px,108px); */
    font-size: 30px;
    color: yellow;
    font-family: 'Poppins';
}
/* .insurencepaysportraite {
    position: absolute;
    transform: translate(139px,262px);
    font-size: 20px;
    color: yellow;
    font-family: poppins;
} */

.span-to{
    color: yellow;
}
.royalflshportraite {
    position: absolute;
    top: 53%;
    /* left: 13%; */
    left: 11%;
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.strightportraite {
    position: absolute;
    top: 55%;
    /* left: 11.5%; */
    left: 11%;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fourofkindportraite {
    position: absolute;
    position: absolute;
    top: 57%;
    left: 11%;
    /* left: 11.5%; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.fullhouseportraite {
    position: absolute;
    top: 59%;
    left: 11%;
    /* left: 18%; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.flushportraite {
    position: absolute;
    top: 61%;
    left: 11%;
    /* left: 25.3%; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.stright {
    position: absolute;
    top: 53%;
    left: 55%;
    /* transform: translate(234px,449px); */
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.threekindportraite {
    position: absolute;
    top: 55%;
    /* transform: translate(234px,470px); */
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    /* font-size: 38px; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 55%;
}

.twopairportraite {
    position: absolute;
    /* transform: translate(236px,491px); */
    top: 57%;
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    /* font-size: 38px; */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 55%;
}

.onepairportraite {
    position: absolute;
    /* transform: translate(236px,515px); */
    top: 59%;
    left: 55%;
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.acekingportraite {
    position: absolute;
    /* transform: translate(236px,541px); */
    top: 61%;
    left: 55%;
    /* transform: scale(1.9,0.5); */
    /* transform: translate(35px,450px); */
    font-size: 36px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

.insurencepaysportraite {
    position: absolute;
    /* transform: translate(121px,260px); */
    /* transform: scale(1.5,0.6); */
    font-size: 31px;
    color: yellow;
    font-family: "Poppins";
    top: 20.5%;
    left: 37%;
    font-weight: 500 !important;
    /* width: 500px !important; */
}

.royalflshlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 28%;
    /* left: 24%; */
    left: 60%;
}

.strightflushlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 31.5%;
    /* left: 24%; */
    left: 60%;
}

.fourofkindlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 35%;
    /* left: 24%; */
    left: 60%;
}

.fullhouselndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    /* left: 24%; */
    left: 60%;
    top: 38.5%;
}

.flushlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    top: 41.5%;
    /* left: 24%; */
    left: 60%;
}

.strightlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    /* top: 26%; */
    top: 54%;
    left: 60%;
}

.threekindlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 60%;
    /* top: 29.5%; */
    top: 57%;
}

.twopairlndspe {
    position: absolute;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 60%;
    /* top: 33%; */
    top: 60%;
}

.onepairlndspe {
    position: absolute;
    /* transform: translate(-28px, -258px); */
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    left: 60%;
    /* top: 36.5%; */
    top: 63%;
}
.chipcenterbns{
    position: absolute;
    top: 5%;
    left: 5%;
    color: #ffffff;
    font-size: 25px;
    z-index: 5;
}
.acekinglndspe {
    position: absolute;
    /* transform: translate(-39px, -218px); */
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    z-index: 1;
    left: 60%;
    /* top: 40%; */
    top: 66%;
}
.bet-text-addtoBet {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 734px;
    left: 1181px;
    color: #ffffff;
    font-size: 22px;
    font-family: 'Poppins';
    /* color: rgb(14, 14, 236); */
    /* color: #370707; */
    z-index: 7;
    width: 71px;
    height: 62px;
    font-weight: bold;
    /* background: black; */
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
}

.bet-text-addtoBet-portrait {
    position: absolute;
    font-size: 38px;
    left: 43%;
    bottom: -26.5%;
    /* top: 129.6%; */
    z-index: -2;
    display: flex;
    flex-direction: column;
    width: 10%;
    /* background: red; */
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    font-family: 'Poppins';
    font-weight: bold;
}
.bet-text-addtoBet-lndsp {
    position: absolute;
    font-size: 38px;
    width: 6%;
    height: 5%;
    /* background: red; */
    font-family: 'Poppins';
    font-weight: bold;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2%;
    z-index: -2;
}

.bet-text-addtoBet20{
    color:#ffffffe1;
}

.bet-text-addtoBet10{
    color:#ffffffe1;
}

.bet-text-addtoinsurence{
    position: absolute;
    font-size: 205px;
    left: 42.5%;
    top: 48%;
    z-index: 89;
    display: flex;
    width: 2%;
    justify-content: center;
    /* opacity: 0; */
    /* background: red; */
    /* visibility: hidden; */
    font-family: 'Poppins';
    font-weight: bold;
}

.bet-text-addtoinsurence20{
    color:#ffffffe1;
}

.bet-text-addtoinsurence10{
    color:#ffffffe1;
}

.bet-text-addtodraw {
    position: absolute;
    font-size: 22px;
    left: 43.8%;
    top: 47.9%;
    z-index: 19;
    display: flex;
    width: 2%;
    justify-content: center;
    /* opacity: 0; */
    /* background: red; */
    /* visibility: hidden; */
    font-family: 'Poppins';
    font-weight: bold;
}
.bet-text-addtodraw20{
    color:#ffffffe1;
}

.bet-text-addtodrwgamestate {
    position: absolute;
    top: 47.5%;
    left: 44.3%;
    font-size: 22px;
    font-family: 'Poppins';
    font-weight: bold;
    /* background: red; */
    color:#ffffffe1;
    
    z-index: 19;
}
.bet-text-addtodraw10{
    color:#ffffffe1;
}
.drawtextopacity{
    opacity: 0.5;

}

.drwnodrop {
    cursor: no-drop;
   
}

.bonuspaytable {
    position: absolute;
    top: 5%;
    
}

.chipsstored {
    position: absolute;
    top: 6%;
    left: 0.5%;

}


.bonusChipsContainerwindows {
    /* position: absolute;
    top: 960px;
    left: 56%;
    z-index: 5;
    width: 4%;
    display: flex;
    justify-content: center; */
    pointer-events: none;
}




.bonusChipsForwindows{
    position: absolute;
    width: 100%;
}
.bonusChipsForwindows_val10 {
    position: absolute;
    color: #ffffff;
    top: 586px;
    left: 1314px;
    font-size: 22px;
    font-family: 'Poppins';
    z-index: 15;
    /* background: red; */
    text-align: center;
    height: 47px;
    width: 61px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    pointer-events: none;
    align-items: center;
}
.bonusChipsForwindows {
    position: absolute;
    color: #ffffff;
    top: 587px;
    left: 1314px;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Poppins';
    z-index: 15;
    text-align: center;
    height: 46px;
    width: 62px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    /* align-items: start; */
}
/* .bonusChipsForwindows {
    position: absolute;
    color: #ffffff;
    top: 596px;
    left: 1307px;
    font-size: 31px;
    font-weight: bold;
    font-family: Poppins;
    z-index: 15;
    text-align: center;
    font-weight: bold;
    height: 34px;
    width: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */


.chip10centerbns {
    position: absolute;
    top: 552px;
    left: 1285px;
    z-index: 15;
    width: 5%;
    /* pointer-events: none; */
    /* z-index: 100; */
}
.chip100centerbns {
    position: absolute;
    top: 552px;
    left: 1284px;
    z-index: 15;
    width: 5%;
    pointer-events: none;
}


.chip500centerbns {
    position: absolute;
    top: 555px;
    left: 1284px;
    z-index: 15;
    width: 5%;
    pointer-events: none;
}


.chip10centerposition {
    position: absolute;
    top: 552.4px;
    /* top: 613.4px; */
    left: 1135px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}
.chip20centerposition {
    position: absolute;
    top: 552px;
    left: 1138px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}

.chip50centerposition {
    position: absolute;
    top: 552px;
    left: 1134px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}

.chip100centerposition {
    position: absolute;
    top: 553px;
    left: 1134px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}

.chip500centerposition {
    position: absolute;
    top: 553px;
    left: 1134px;
    z-index: 19;
    width: 5%;
    pointer-events: none;
}
.chipsstoredcontainerLndsp{
    /* position: relative;
    left: 46.8%;
    margin: auto;
    top: 605px;
    bottom: 0;
    z-index: 2; */

    position: absolute;
    left: 46.8%;
    margin: auto;
    top: 627px;
    z-index: 2;
    width: 16%;
   
}
.chip-center-position{
    /* position: relative;
    width: 12%;
   
    top: -2165px;
    left: 37%;
    display: flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    width: 12%;
    /* top: 0; */
    top: -1130px;
    left: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.chip-center-position-lndsp {
    /* position: relative;
    width: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; */
    

    position: relative;
    width: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}


.chip10centerpositionPortrait {
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;

}



.chip20centerpositionPortrait{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
}



.chip50centerpositionPortrait {
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
}

.chip100centerpositionPortrait{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
}



.chip500centerpositionPortrait{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
    

}
.container_playerdealer {
    position: absolute;
    top: 568px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 57%;
   
}
#container_playerdealer h1::before {
    content: "DEALER";
    color: #a1a1a1;
    position: absolute;
    -webkit-text-stroke: 3px #bcd7bc;
}
#container_playerdealer h2::before {
    content: "PLAYER";
    color: #a1a1a1;
    position: absolute;
    -webkit-text-stroke: 3px #bcd7bc;
}
.Dealer {
    font-family: 'Poppins';
    color:#a1a1a1;
    font-size: 55px;
    position: absolute;
    left: 123px;
}

.Dealer_windows {
    font-family: 'Poppins';
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    left: 605px;
    top: -308px;
}
.Player_windows {
    font-family: 'Poppins';
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    right: 558px;
    top: 278px;
}
.Player{
    font-family: 'Poppins';
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    right: 121px;
}
.cntr_pyrdlr_prt{
          
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 78%;
    position: absolute;
    top: 22%;
    font-size: 38px;
    font-family: "Poppins";
    padding: 50px 0;
}

.cntr_pyrdlr_lnsp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 52%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 38px;
    font-family: "Poppins";
    /* margin: auto; */
}
.antebetChipPortrait10{
    position: absolute;
    z-index: 9;
    font-size: 40px;
    color: #ffffff;
    pointer-events: none;
    display: flex;
    width: 12%;
    justify-content: center;
    
}

.antebetChipPortrait101{
    color:#ffffff;
}


.antebetChipPortrait20{
    position: absolute;
    z-index: 9;
    font-size: 38px;
    color: #ffffff;
    pointer-events: none;
    display: flex;
    width: 12%;
    justify-content: center;
    font-family: "Poppins";
}


.antebetChipPortrait500{
    position: absolute;
    z-index: 9;
    font-size: 40px;
    color: #ffffff;
    pointer-events: none;
    display: flex;
    width: 12%;
    justify-content: center;
    
}

.bonusChipsContainerForPortrait {
    position: absolute;
    top: 954px;
    left: 57.8%;
    z-index: 5;
    width: 12%;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.bonusChipsCntrFrLndsp {
    position: absolute;
    left: 175px;
    z-index: 5;
    /* width: 6%; */
    width: 38%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    align-items: center;
}


.bonusChipsForPortrait{
    position: absolute;
    width: 100%;
}
.bonusChipsTextForPortrait{
    position: absolute;
    color: #ffffff;
    top: 50px;
    font-size: 38px;
    z-index: 10;
    text-align: center;
}

.bonusChipsTextfrLndsp {
    position: absolute;
    color: #ffffff;
    font-size: 38px;
    z-index: 10;
    text-align: center;
}




.betbtntextrebet {
    position: absolute;
    top: 2%;
    left: 3.5%;
    font-size: 19px;
    font-family: 'Poppins';

}

.bnspoiner {
    cursor: no-drop;
    opacity: 0.7;
}

.rebet {
    position: absolute;
    top: 79%;
    left: 66.5%;
    color: #ffffff;
    font-size: 22px;
    font-family: 'Poppins';
    z-index: 1;
    /* line-height: 3; */
}

.rebet2 {
    position: absolute;
    top: 66.5%;
    left: 73.5%;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Poppins';
    z-index: 1;
    /* line-height: 3; */
}

.nonpointer{
    pointer-events: none;
}
.addpointer {
    cursor: pointer;
}

.antecircleactivewindows {
    position: absolute;
    cursor: pointer;
    /* top: 39%; */
    /* margin-top: 20.7%;
    left: 46.1%;
    height: 152px;
    width: 150px;
    border-radius: 146px;
    border: 13px red solid; */
}

.gamecover_enbledisable {
    position: absolute;
    /* background: red; */
    opacity: 0.5;
    width: 3000px;
    height: 1245px;
    top: 5%;
    left: 5%;
}
.windowsinfo {
    position: absolute;
    /* top: 932px; */
    font-size: 27px;
    font-family: 'Poppins';
    z-index: 5;
    color: #ffffff;
    /* background: red; */
    width: 419px;
    height: 254px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    left: 0px;
    right: 0;
    margin: auto;
    padding: auto;
}
.portraitinfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0% 3% 0% 3%;
    justify-content: center;
    top: 2010px;
    position: absolute;
    font-size: 38px;
    font-family: "Poppins";
    z-index: 5;
    color: #ffffff;
    height: 130px;
    align-items: center;
}

.lndspinfo {
    position: absolute;
    /* top: 890px; */
    top: 420px;
    font-size: 35px;
    font-family: "Poppins";
    /* z-index: 5; */
    z-index: 0;
    color: #ffffff;
    width: 23%;
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-weight: bold; */
    padding: 1%;
    border: 4px solid #00ffadb0;
    border-radius: 10%;
    left: 15%;
    /* right: 0; */
    /* margin: auto; */
}
.insurencenodrop{
    cursor: no-drop;
    opacity: 0.5;
}

  
.windowspokerranking {
    position: absolute;
    top: 937px;
    /* bottom: 19px; */
    left: 44.7%;
    color: black;
    font-size: 33px;
    font-family: 'Poppins';
    z-index: 5;
    background: #FF5722;
    width: 296px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: bold;
    border: 3px solid #8a9877;
    border-radius: 16px;
    box-shadow: 0px 8px #699a32;
}
.pokerrankingPortait{
    position: absolute;
    /* top: 1680px; */
    /* top: 1668px; */
    top: 1664px;
    /* margin-top: 20.8%; */
    /* left: 36%; */
    left: 35%;
    color: white;
    font-size: 35px;
    font-family: "Poppins";
    z-index: 15;
    background: #9a5b0b;
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0.5%;

}

.pokerrankingPlayerLndsp {
    position: absolute;
    top: 1045px;
    /* top: 770px; */
    left: 43.5%;
    /* left: 63%; */
    color: white;
    font-size: 38px;
    font-family: "Poppins";
    z-index: 15;
    background: #9a5b0b;
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.3%;
}

.windowspokerrankingdeler {
    position: absolute;
    top: 329px;
    /* margin-top: 22%; */
    left: 44.3%;
    color: black;
    font-size: 30px;
    font-family: 'Poppins';
    z-index: 5;
    background: #ff5722;
    width: 296px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 3px solid #8a9877;
    border-radius: 16px;
    border: 3px solid #8a9877;
    box-shadow: 0px 8px #699a32;
}

.pokerrankingdelerPortrait{
    position: absolute;
    left: 35%;
    top: 650px;
    color: white;
    font-size: 35px;
    font-family: "Poppins";
    z-index: 15;
    background: #9a5b0b;
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5%;
}
.pokerrankingdelerLndSpe {
    position: absolute;
    left: 42.7%;
    /* top: 288px; */
    top: 260px;
    /* top: 790px; */
    color: white;
    font-size: 35px;
    font-family: "Poppins";
    z-index: 15;
    background: #9a5b0b;
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.3%;
}
.antebet_textval10 {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 596px;
    left: 1163px;
    font-size: 22px;
    font-family: 'Poppins';
    color:#ffffff;
    /* color: #370707; */
    z-index: 19;
    width: 63px;
    font-weight: bold;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    pointer-events: none;
}
.antebet_text20 {
    pointer-events: none;
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 578px;
    left: 1171px;
    font-size: 22px;
    font-family: 'Poppins';
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 58px;
    height: 61px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.antebet_text50 {
    position: absolute;
    top: 584px;
    left: 1159px;
    font-size: 22px;
    font-family: 'Poppins';
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 70px;
    height: 55px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
    /* display: inline-block; */
}
.antebet_text100 {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 586px;
    left: 1160px;
    font-size: 22px;
    font-family: 'Poppins';
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 68px;
    height: 50px;
    /* background: #2b0404; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
     text-align: center;
     pointer-events: none;
}
.drawbetchiptext{
    position: absolute;
    top: 58%;
    left: 25.5%;
    color: #ffffff;
    font-size: 26px;
    z-index: 20px;

}
.bet_rsp {
    position: absolute;
    top: 58%;
    left: 25.5%;
    color: #ffffff;
    font-size: 26px;
    z-index: 20px;
}



.betchipsaddtobonustxt {
    position: absolute;
    margin-top: 25.5%;
    /* margin-top: 293%; 
     top: 120%;  */
    left: 54%;
    color: black;
    color: #fff;
    font-size: 34px;
    font-family: 'Poppins';
    z-index: 10;
}

.chip_10 {
    font-size: 50px;
    color: black;
    font-family: 'Courier New', Courier, 'Poppins';
}

.optionsmenu {
    position: fixed;
    top: 6%;
    left: -21.5%;
    height: 611px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

}
.optionsmenu_portraite {
    position: fixed;
    top: 6%;
    left: -41.5%;
    /* height: 611px; */
    height: 400px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

}
.optionsmenu_lndspe {
    position: fixed;
    top: 5%;
    left: -22%;
    height: 611px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
/* .exithistory {
    animation: anim 1s ease-in-out forwards;
}

@keyframes anim {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1.5);
    }
} */


.exitoptionmenu {
    z-index: 44;
    position: absolute;
    top: 1%;
    left: -23%;
}

.topmenu {
    position: absolute;
    top: 0%;
    left: -102.5%;
    height: 94px;
    width: 309px;
    background: linear-gradient(#ffffff, #012303);
}

.optionheading {
    font-size: 37px;
    padding: 32px;
    font-family: 'Poppins';
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgb(44, 22, 1);
}
.limitstext {
    position: absolute;
    /* top: 26%; */
    top: 15%;
    left: -87%;
    height: 80px;
    width: 257px;
    background-color: #a9761d;
    border-radius: 36px;
    color: #ffffff;
    font-size: 35px;
    font-family: 'Poppins';
}

.helptext {
    position: absolute;
    /* top: 48%; */
    top: 40%;
    left: -87%;
    height: 80px;
    width: 257px;
    background-color: #a91d66;
    border-radius: 36px;
    color: #ffffff;
    font-size: 35px;
    font-family: 'Poppins';
}

.historytext {
    position: absolute;
    /* top: 70%; */
    top: 65%;
    left: -87%;
    height: 80px;
    width: 257px;
    background-color: #2da91d;
    border-radius: 36px;
    color: #ffffff;
    font-size: 35px;
    font-family: 'Poppins';


}

.arraowtext {
    position: absolute;
    top: 88%;
    left: 16.5%;
    height: 57px;
    width: 201px;
    /* background-color: #1d92a9; */
    border-radius: 36px;
    color: #ffffff;
    font-size: 25px;
    font-family: 'Poppins';
}
.chip_10text{
    color:#ffffff;
}
.valtext_windows_chip10 {
    font-size: 31px;
    font-family: 'Poppins';
    position: absolute;

    font-weight: bold;
    width: 63px;
    height: 75px;
    font-weight: bold;
    /* background: #34bf1b; */
    /* top: 91%; */
    bottom: 63px;
    /* right: 19px; */
    /* left: 5%; */
    z-index: 4;
    right: 202px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.valtext_windows {
    color: #ffffff;
    font-size: 36px;
    font-family: 'Poppins';
    position: absolute;
    font-weight: bold;
    bottom: 5%;
    font-weight: bold;
    width: 72px;
    height: 73px;
    /* background: #b31212; */
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 193px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-items: flex-start; */
}
.valtext1{
    color: #ffffff;
    font-size: 25px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 6%;
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 8.7%;
}

.valtext1 {
    color: #ffffff;
    font-size: 40px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 4%;
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 8.7%;
}

.valtext2 {
    color: #ffffff;
    font-size: 25px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 4%;
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 8.7%;

}

.valtext3 {
    color: #ffffff;
    font-size: 25px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 6%;
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 8.7%;

}

.valtext4 {
    color: #ffffff;
    font-size: 25px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 6%;
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 8.7%;

}

.valtextPortrait{
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 4%;
    z-index: 4;
    right: 44%;
    width: 12%;
    display: flex;
    justify-content: center;
}



.valtextLandscape{
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    position: absolute;
    bottom: 48%;
    z-index: 4;
    right: 2.5%;
    width: 5%;
    display: flex;
    justify-content: center;

}

.valtextPortrait1{
    color:#ffffff;
    
}
.grid table {
    position: absolute;
    width: 14%;
    height: 306px;
    right: 597px;
    top: 337px;
    font-family: arial, sans-serif;
    border-collapse: collapse;
}
.rankingInfoTablePortrait{
    position: absolute;
    top: 1640px !important;
    left: 3% !important;
    width: 41% !important;
    height: 462px !important;
    right: 3.5% !important;
    font-family: "Poppins" !important;
    border-collapse: collapse !important;
    border: 4px #edeb8b solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background-color: #000000ed !important;
    /* background-image: linear-gradient(to right, #000000ed, #00000082, #000000ed); */
    z-index: 25 !important;
}

.rankingInfoTableLndsp {
    position: absolute !important;
    left: 3% !important;
    width: 23% !important;
    height: 462px !important;
    font-family: "Poppins" !important;
    border-collapse: collapse !important;
    border: 4px #edeb8b solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background-color: #000000b3 !important;
    z-index: 25 !important;
    top: 500px !important;
}
/* table tbody{
    position: absolute;
    top: 20%;
    left: 10%;
    transform: translate(-2%, 2%);
} */

/* table thead{
    position: absolute;
    top: 5%;
    width: 100%;
    left: 10%;
    transform: translate(-3%, -5%);
} */




/* .chipanimpoker{
    transform: translate(-956px,-599px);
    animation-duration: 1s;
 } */
 .betpannelrussianwindows {
    position: absolute;
    top: 0%;
    left: 1.2%;
    height: 100%;
    width: 99%;
}
.bonuswinhglt{
    color: #ffffff;
    font-size: 23px;
    font-family: 'Poppins';
    text-align: left;
    /* animation: blink normal 1s infinite ease-in-out; */


}
.bonuswinhglt_portrite{
    color: #ffffff;
    font-size: 34px;
    font-family: "Poppins";
    text-align: left;
    /* animation: blink normal 1s infinite ease-in-out; */

}

.num {
    color: #a1a1a1;
    font-size: 23px;
    font-family: 'Poppins';
    text-align: left;
}
.bonuspayouthglt{
    color: #fdfd7b;
    font-size: 40px;
     font-family: 'Poppins';
    /* animation: blink normal 1s infinite ease-in-out; */


}
.bonuspayouthglt_portraite{
    color: #fdfd7b;
    font-size: 34px;
    font-family: "Poppins";
    /* animation: blink normal 1s infinite ease-in-out; */



}
.numPortrait{
    color: #fdfd7b;
    font-size: 34px;
    font-family: "Poppins";
    text-align: left;
}

.windowstablehead {
    position: absolute;
    font-family: 'Poppins';
    color: #fffe00;
    font-size: 27px;
    margin-top: -77px;
    /* top: -89%; */
    left: 25%;
}
.portraittablehead{
    position: absolute;
    font-family: 'Poppins';
    color: #fffe00;
    font-size: 40px;
    top: 10%;
    left: 5%;
}

.numbers {
    height: 5px;
    color: #a1a1a1;
    /* border: 5px solid; */
    width: 50px;
    /* margin-top: 50%; */
}

.numbersval {
    font-size: 23px;
    font-family: 'Poppins';
    text-align: end;
}

.numbersvalPortrait{
    font-size: 34px;
    font-family: "Poppins";
    text-align: end;
}

/* .value{
    height: 500px;
    border: 5px solid;
    width: 50px;

} */
.insurencetext {
    position: absolute;
    top: 34%;
    /* left: 46%; */
    /* transform: translate(646px,206px); */
    font-size: 27px;
    color: #d1c9c9;
    font-family: 'Poppins';
    font-weight: bolder;
    z-index: 1;
    /* background: red; */
    width: 167px;
    text-align: center;
    overflow-wrap: anywhere;
    height: 119px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
}



.insurencepayslndspe {
    position: absolute;
    /* transform: translate(-96px, 54px); */
    font-size: 38px;
    color: yellow;
    font-family: 'Poppins ';
    font-weight: bolder;
    left: 60%;
    top: 25%;
}

.confirmbuy-dealerpopup{
    visibility: hidden;
}

.popup_cnfrmbuydealrcard {
    position: absolute;
    top: 484PX;
    left: 32%;
    width: 869px;
    height: 250px;
    background-color: #ffffff;
    border-radius: 25px;
    /* z-index: 8; */
    z-index: 30;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    /* box-shadow: 6px 7px 100px peachpuff; */
}
.popup_cnfrmbuydealrcardPortrait{
    position: absolute;
    /* top: 49%; */
    top: 900px;
    left: 13.5%;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 1000;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.nobutton_popup {
    position: absolute;
    top: 72%;
    left: 49%;
    width: 137px;
    height: 50px;
    border-radius: 16px;
    color: #ffffff;
    font-size: 24px;
    font-family: 'poppins';
    /* border: 1px solid; */
    background-color: #931111;
}
.nobutton_popup_portrait {
    position: absolute;
    top: 65%;
    left: 55%;
    width: 150px;
    height: 70px;
    border-radius: 16px;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    /* border: 1px solid; */
    background-color: #931111;
}

.yesbutton_popup {
    position: absolute;
    top: 72%;
    left: 32%;
    color: #ffffff;
    font-size: 24px;
    font-family: 'poppins';
    width: 137px;
    height: 50px;
    border: 2px solid;
    background-color: #536505;
    border-radius: 16px;
}

.yesbutton_popup_portrait{
    position: absolute;
    top: 65%;
    left: 30%;
    color: #ffffff;
    font-size: 38px;
    font-family: 'Poppins';
    width: 150px;
    height: 70px;
    border: 2px solid;
    background-color: #536505;
    border-radius: 16px;
}

.popup_cnfrmbuydealrcardLndsp{

    /* position: absolute; */
    position: relative;
    /* top: 630px; */
    top: 460px;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    /* z-index: 22; */
    z-index: 1000;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}



.bonustext {
    position: absolute;
    top: 41.5%;
    left: 53.5%;
    font-size: 27px;
    color: #d1c9c9;
       font-weight: bolder;
    color: #d1c9c9;
    font-family: 'Poppins';
    z-index: 1;
    width: 108px;
    overflow-wrap: anywhere;
    height: 177px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    }

    .bonustext {
        position: absolute;
        top: 42%;
        left: 53.5%;
        /* transform: translate(746px,292px); */
        font-size: 27px;
        color: #d1c9c9;
        font-family: 'Poppins';
        z-index: 1;
        /* background: red; */
        width: 108px;
        overflow-wrap: anywhere;
        height: 177px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }


.chiptoinsurence {
    position: absolute;
    top: 426px;
    left: 1112px;
    z-index: 5;
    width: 4%;
}

.chiptoinsurence_windows {
    position: absolute;
    left: 1133px;
    top: 414px;
    z-index: 5;
    width: 5%;
    pointer-events: none;
}




.chiptoinsurencePortrait{
    position: absolute;
    top: 755px;
    left: 42%;
    z-index: 5;
    width: 12%;
    pointer-events: none;
}

.insurence {
    position: absolute;
    top: 66%;
    left: 74.3%;
    color: white;
    font-size: 26px;
    font-family: monospace;
    font-weight: bold;
    z-index: 1;
    width: 118px;
    pointer-events: none;
    text-align: center;
    display: flex;
    /* background: red; */
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}
.insurencePortrait{
    position: absolute;
    top: 66.5%;
    left: 83%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 17;
    width: 105px;
    text-align: center;
    /* background: red; */
    overflow-wrap: anywhere;
    line-height: 1;
    width: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    }
    
    
   .insurencewindows{
    position: absolute;
    top: 68.2%;
    left: 83%;
    color: white;
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 17;
    width: 105px;
    text-align: center;
    /* background: red; */
    overflow-wrap: anywhere;
    line-height: 1;
    width: 150px;

} 
    
.insuranceTextwndwsChip10 {
    color:#ffffffe1;
    position: absolute;
    font-size: 30px;
    font-family: 'Poppins';
    left: 49.3%;
    top: 437px;
    z-index: 5;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3%;
    height: 68px;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
}


.LimitsPopup {
    position: absolute;
    top: 400px;
    left: 0px;
    right: 0;
    z-index: 34;
    margin: auto;
    width: 750px;
    height: 300px;
    background: repeating-linear-gradient(45deg, orange, transparent 14px);
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins';
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.LimitsPopup_landspe {
    position: absolute;
    /* top: 149px; */
    top: 400px;
    left: 0px;
    right: 0;
    margin: auto;
    width: 750px;
    height: 300px;
    background: repeating-linear-gradient(45deg, orange, transparent 14px);
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 38px;
    font-family: "poppins";
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.LimitsPopup_portraite {
    position: absolute;
    top: 850px;
    left: 0;
    right: 0;
    margin: auto;
    width: 750px;
    height: 300px;
    /* background: repeating-linear-gradient(45deg, orange, transparent 14px); */
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 38px;
    font-family: "poppins";
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.insuranceText_windows {
    position: absolute;
    font-size: 22px;
    color: #f1f1f5;
    font-family: 'Poppins';
    left: 49.7%;
    top: 453px;
    z-index: 5;
    /* background: red; */
    /* display: flex; */
    width: 2%;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
}

.insuranceTextPortrait{
    position: absolute;
    font-size: 38px;
    color: #f1f1f5;
    font-family: "Poppins";
    left: 42%;
    top: 810px;
    z-index: 5;
    /* display: flex; */
    width: 12%;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
}
.chip-to-insurence-lndsp{
    /* position: relative;
    width: 100%;
    top: -180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: -47%;
    pointer-events: none; */

    position: relative;
    width: 38%;
    top: -180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 0%;
    pointer-events: none;
}

.chiptoinsurenceLndSpe{
    position: absolute;
    width: 100%;
    /* width: 6%; */
}

.insuranceTextLndspe{
    position: absolute;
    font-size: 38px;
    color: #f1f1f5;
    font-family: "Poppins";
    width: 100%;
    /* width: 5%; */
    text-align: center;
    font-weight: bold;
}


.insuranceTextPortraitChip10{
    color:#ffffffe1;
}


.insurenceopacity {
    opacity: 0.5;
    cursor: pointer;
}
.noinsurence {
    position: absolute;
    top: 75.1%;
    left: 66.6%;
    /* transform: translate(867px,534px); */
    color: white;
    font-size: 24px;
    font-family: monospace;
    font-weight: bold;
    z-index: 2;
    /* pointer-events: none; */
    width: 133px;
    height: 127px;
    text-align: center;
    overflow-wrap: anywhere;
    display: flex;
    /* background: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.noinsurencePortrait{
    position: absolute;
    top: 74%;
    left: 74.5%;
    color: white;
    font-size: 38px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 2;
    pointer-events: none;
    text-align: center;
    overflow-wrap: anywhere;
    line-height: 1;
    width: 11%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.bnsblink {
    animation: blink normal 1s infinite ease-in-out;
}

/* .betblink{
    animation: blink normal 1s infinite ease-in-out;
} */
.betblink{
    animation: blink normal 1s infinite ease-in-out;

}
.betactive {
    position: absolute;
    top: 51.4%;
    left: 46.5%;
    width: 166px;
    z-index: 4;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(89deg);
}

.betactivePortrait {
    position: absolute;
    top: 50%;
    left: 38.5%;
    width: 17%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(89deg);
}

.betactiveLndsp {
    position: absolute;
    top: 57.5%;
    left: 41.5%;
    width: 29.5%;
    z-index: 4;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(89deg);
}





.insurenceactivewindows {
    position: absolute;
    top: 29%;
    left: 46.5%;
    width: 166px;
    z-index: 4;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(-91deg);
}


.insurenceblink{
    animation: blink normal 1s infinite ease-in-out;

}
.insurenceactivePortrait {
    position: absolute;
    top: 31%;
    left: 38.5%;
    width: 17%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(-91deg);
}

.insurenceactiveLndsp {
    position: absolute;
    top: -11%;
    left: 42%;
    width: 29%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
    transform: rotate(270deg);
}
.bnsctivewindows {
    position: absolute;
    top: 40%;
    left: 52.6%;
    width: 167px;
    z-index: 1;
    /* border: 168px red solid; */
}

.bnsactivePortrait{
    position: absolute;
    top: 40.9%;
    left: 55%;
    width: 16%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
}

.bnsactiveLandscape{
    position: relative;
    /* top: 17%; */
    top: 25%;
    left: 67%;
    width: 28%;
    z-index: 1;
    animation: blink normal 1s infinite ease-in-out;
}

.bettext {
    position: absolute;
    top: 57%;
    /* transform: translate(665px,376px); */
    font-size: 27px;
    font-weight: bolder;
    color: #d1c9c9;
    font-family: 'Poppins';
    z-index: 1;
    /* background: red; */
    width: 189px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: bolder;
}


.buydrawtext {
    position: absolute;
    top: 43%;
    left: 41.5%;
    /* transform: translate(542px,294px); */
    font-size: 24px;
    color: #D1C9CB;
    font-family: 'Poppins';
    font-weight: bolder;
    z-index: 1;
    /* background: red; */
    width: 105px;
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.insurencetextportraite {
    position: absolute;
    top: 36%;
    left: 38%;
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color: #a1a1a1;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    display: flex;
    justify-content: center;
    width: 18%;
    height: 5%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bonustextportraite {
    position: absolute;
    /* transform: translate(237px,410px); */
    top: 44.5%;
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color: #a1a1a1;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    /* left: 57%; */
    left: 57.3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 10%;
    overflow-wrap: anywhere;
    height: 5%;
}

.bettextportraite {
    position: absolute;
    /* transform: translate(178px,482px); */
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color: #a1a1a1;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    top: 53%;
    left: 37.5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 18%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.buydrawtextportraite {
    position: absolute;
    top: 45%;
    /* transform: translate(101px,415px); */
    /* transform: scale(2,0.7); */
    /* transform: translate(157px,351px); */
    font-size: 38px;
    color: #a1a1a1;
    /* opacity: 0.7; */
    font-family: 'Poppins';
    left: 24.5%;
    /* margin: 0px; */
    /* width: 10%; */
    /* padding: 0px; */
    line-height: 1;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 12%;
}

.insurencetextlndspe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 30%;
    position: absolute;
    top: 10%;
    left: 41.5%;
    font-size: 38px;
    color: #a1a1a1;
    font-family: 'Poppins';
    overflow-wrap: anywhere;
}

.bonustextlndspe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 20%;
    overflow-wrap: anywhere;
    position: absolute;
    top: 40%;
    left: 70%;
    font-size: 40px;
    color: #a1a1a1;
    font-family: 'Poppins';
    pointer-events: none;
}

.bettextlndspe {
    overflow-wrap: anywhere;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 20%;
    width: 28%;
    top: 70%;
    left: 42%;
    font-size: 40px;
    color: #a1a1a1;
    font-family: 'Poppins';
    pointer-events: none;
}

.buydrawtextlndspe {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    width: 22%;
    position: absolute;
    top: 43%;
    left: 22%;
    font-size: 40px;
    color: #a1a1a1;
    line-height: 0.8;
    font-family: 'Poppins';
    pointer-events: none;
}

.insurenceratio {
    color: white;
}

.royaltocolor {
    color: yellow;
}

.royalflshportraiteto {
    color: yellow;

}

#insurencetextportraite {
    position: absolute;
    transform: translate(60px, 19px);
    font-size: 20px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

#bonustextportraite {
    position: absolute;
    transform: translate(745px, 291px);
    font-size: 20px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

#bettextportraite {
    position: absolute;
    transform: translate(665px, 376px);
    font-size: 20px;
    color: #a1a1a1;
    font-family: 'Poppins';
}

#buydrawtextportraite {
    position: absolute;
    transform: translate(542px, 294px);
    font-size: 17px;
    color: #a1a1a1;
    font-family: 'Poppins';
    z-index: 10;
}

.insurenceratioportraite {
    color: white;
}

.yadav {
    color: white;
    font-size: 56px;
}

.crdscover {
    position: absolute;
    right: 849px;
    bottom: -245%;
    /* margin-top: 8%; */
    /* left: 56px; */
    width: 578px;
    height: 232px;
    /* background: red; */
    z-index: 27;
}
.bet_pannelcoverrussian {
    position: absolute;
    top: 29%;
    left: 41%;
    width: 495px;
    height: 470px;
    /* background: red; */
    z-index: 27;
}
.buttons_cover {
    position: absolute;
    top: 804px;
    left: 49%;
    width: 840px;
    height: 455px;
    /* background: red; */
    z-index: 27;
}
.bet_pannelinsurence {
    position: absolute;
    top: 41%;
    left: 40%;
    width: 495px;
    height: 248px;
    /* background: red; */
    z-index: 27;
}

/* .bet_pannelinsurence {
    position: absolute;
    top: 41.7%;
    left: 40%;
    width: 495px;
    height: 470px;
    background: red;
    z-index: 27;
} */

.PlayerCardpannelcoverPortrait{
    display: flex;
    justify-content: center;
    background-color: transparent;
    /* background-color: #e12be68f; */
    position: absolute;
    top: 62%;
    left: 25%;
    width: 50%;
    height: 14%;
    /* z-index: 21; */
    z-index: 999;
}

.PlayerCardpannelcoverLndsp{
    display: flex;
    justify-content: center;
    background-color: transparent;
    /* background-color: #e12be68f; */
    position: absolute;
    /* top: 48%; */
    top: 68%;
    left: 37%;
    width: 23%;
    height: 25%;
    z-index: 999;
    /* border: 2px solid red; */
}

.mobileAntiMaskCover{
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    position: absolute;
    top: 38.5%;
    left: 37.5%;
    /* width: 20%; */
    width: 35%;
    height: 11%;
    /* z-index: 10; */
    z-index: 999;
    background-color:transparent;
    /* background-color: #e12be68f; */
    /* border-radius: 50%; */

}

.mobileLndspAntiMaskCover{
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    position: absolute;
    top: 41%;
    left: 45.5%;
    /* width: 8%; */
    width: 16%;
    height: 18%;
    /* z-index: 10; */
    z-index: 999;
    /* background-color: #e12be68f; */
    background-color: transparent;
    /* border-radius: 50%; */
   

} 

.RP_MainDiv {
    position: absolute;
    position: fixed !important;
    width: 102%;
    height: 101%;
    left: -1%;
    /* background-color: red; */
    /* top: 102%; */
}

.optBtn {
    width: 15%;
    height: 15%;
}


.player-dealer-card-container {
    /* border: 2px solid red; */
    /* background-color: #f8124ecf; */
    /* height: 250px; */
    width: 100%;
    position: absolute;
    /* left: 46%; */
    /* top: 30%; */
    z-index: 1;
    display: flex;
    /* display: none; */
    justify-content: center;
    /* border-radius: 100%; */

}

.stage-top-mobile-portrait-rp {
    top: 10%;
    /* top: 7%; */
    left: -1.5%;
}
.stage-top-mobile-landscape-rp {
    /* top: 13%; */
    left: -0.5%;
    /* left: 8%; */
    top: 8%;
    /* left: 2%; */
}

.stage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 17px solid green;
    position: absolute;
    top: 12%;
    z-index: 111;
    /* left: 50%; */
    width: 100%;
}

.alertTxt {
    color: black;
    font-size: 33px;
    text-align: center;
    /* justify-content: center; */
    position: absolute;
    padding: 20px;
}
.alertTxtPortrait{
    color: black;
    font-size: 40px;
    text-align: center;
    justify-content: center;
    position: absolute;
    padding: 20px;
}

.antebet {
    position: absolute;
    margin-top: 25%;
    margin-left: 48.5%;
    /* top: 43%; 
    /left: 5%;  */
    font-size: 26px;
    color: #ffffff !important;
    z-index: 18;
}

/* span#antebetval_10 {
    position: absolute;
    top: 595px;
    left: 1135px;
    font-size: 30px;
    font-family: Poppins;
    z-index: 11;
   color:#ffffff;
    font-weight: bold;
} */

.dealerBuyCard{
    height: 150px;
    width: 150px;
    /* background-color: red; */
    position: relative;
    left: 44%;
    transform: translate(0%);
    top: 6%;
    width: 12%;
}

.dealerBuyCardDrawButtonPortrait{
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}
#dealerChipsAddToBuyButton img{ 
    width: 100%;
}

.dealerBuyCardtext {
    position: absolute;
    font-size: 40px;
    /* top: 38%; */
    top: 32%;
    z-index: -2;
    display: flex;
    width: 12%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-family: 'Poppins';
    font-weight: bold;
}

.dealerBuyCardLndsp{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 6%;
    right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.dealerBuyCardtextLndsp {
    position: absolute;
    font-size: 38px;
    z-index: -2;
    display: flex;
    width: 12%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-family: 'Poppins';
    font-weight: bold;
}





.playerIdDetailswindows {
    position: absolute;
    /* width: 79%; */
    color: #fff;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-size: 25px;
    left: 7%;
    top: 7%;
    font-family: 'Poppins';
    z-index: 5;
}

.playerIdDetailsPortrait{
    position: absolute;
    width: 32%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: end;
    /* font-size: 40px; */
    font-size: 35px;
    left: 65%;
    top: 8.7%;
    font-family: 'Poppins';
}
.playerIdDetailsLandscape {
    position: absolute;
    width: 20%;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 38px;
    bottom: 3%;
    left: 3%;
    font-family: 'Poppins';
}
.dealer-player-shows{
    display: flex;
    justify-content: space-between;
    width: 54%;
    position: absolute;
    font-size: 40px;
    top: 69%;
}
.minmaxbets_windows {
    position: absolute;
    top: 201px;
    left: 153px;
    font-size: 34px;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    color: white;
}

.minmaxbets_portrait {
    position: absolute;
    top: 270px;
    left: 2%;
    font-size: 35px;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    color: #fff;
   
}
.minmaxbets_lndsp{
    position: absolute;
    top: 250px;
    /* left: 2%; */
    left: 3%;
    font-size: 38px;
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    color: #ffffff;
}

.exit_popup{
    position: absolute;
    right: 2%;
    top: 2%;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 100;
}
.showprevhsrytble {
    position: absolute;
    top: 160px;
    left: 340px;
    width: 1853px;
    /* height: 573px; */
    height: 640px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;
    /* display: flex; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* width: 100%; */
}

/* #heading_hstiry tr td{
    padding-right: 100px;
}   */


/* Ensures spacing between all table cells */
#histry_russian {
    border-collapse: separate; /* Required for border-spacing to work */
    border-spacing: 10px; /* Adds spacing between cells in both <thead> and <tbody> */
  }
  
  /* Adds padding inside each cell for additional spacing */
  #histry_russian th,
  #histry_russian td {
    padding: 10px;
    text-align: center; /* Centers content, optional */
  } 



.showprevhsrytblev::-webkit-scrollbar {
    width: 10px; 
  }
  
  .showprevhsrytble::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;
  }

.histry_body {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
    /* margin-top: -70px; */
}
.histry_russian {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.exihstrybutton {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstiry {
    font-size: 40px;
    font-family: 'Poppins';
    color: papayawhip;
}
.showprevhsrytbleportraite {
    position: absolute;
    top: 701px;
    left: 13px;
    width: 1221px;
    height: 760px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.historycards{
    position: absolute;
    top: 5px;
    left: 5px;
}
.histry_bodyportraite {
    /* width:900px; */
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
}
.histry_russianportraite {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}
.exihstrybuttonportraite {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstiryportraite{
    font-size: 38px;
    font-family: "Poppins";
    color: papayawhip;
}

.showprevhsrytblelndspe {
    position: absolute;
    top: 335px;
    left: 340px;
    width: 1853px;
    /* height: 565px; */
    height: 630px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.histry_bodylndspe {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
}
.histry_russianlndspe {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}
.exihstrybuttonlndspe {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstirylndspe{
    font-size: 38px;
    font-family: "Poppins";
    color: papayawhip;
}
.cardsimages {
    width: 33px;
}

.Gamerulescontainer {
    position: absolute;
    height: 1221px;
    width: 32%;
    background: #ffffff;
    color: black !important;
    display: flex;
    font-family: 'Poppins';
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 31;
}
.Gamerulescontainer ul li {
    padding: 17px;
}
.maincontainer{
    position: absolute;

}
.gameruleMainHeading {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color: black;
}
.unorderedlistitems {
    /* margin-top: 2817px; */
    padding: 0px 0px 0px 30px;
    height: 85%;
}


.Gamerulescontainerport {
    position: absolute;
    height: 1362px;
    width: 59%;
    background: #ffffff;
    color: black !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 110px;
    right: 0;
    top: 210px;
    margin: auto;
    z-index: 31;
}
.Gamerulescontainerport ul li {
    padding: 17px;
}
.maincontainer{
    position: absolute;

}
.gameruleMainHeadingport {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color: black;
}
.unorderedlistitemsport {
    /* margin-top: 2817px; */
    padding: 0px 0px 0px 30px;
    height: 85%;
}
.Gamerulescontainerlnd {
    position: absolute;
    height: 1221px;
    width: 32%;
    background: #ffffff;
    color: black !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 31;
}
.Gamerulescontainerlnd ul li {
    padding: 17px;
}
.maincontainer{
    position: absolute;

}
.gameruleMainHeadinglnd {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color: black;
}
.unorderedlistitemslnd {
    /* margin-top: 2817px; */
    padding: 0px 0px 0px 30px;
    height: 85%;
}

.rsPokerCover{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 900;
    display: block;
}

#rankinginfo{
    text-align: center;
}
#rankinginfodeler{
    text-align: center;
}

 /* .waviy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(111, 96, 153, 0.6));
    text-align: center;
    margin-top: -220px;
    white-space: nowrap;
    
  } */
  .waviy {
    position: absolute;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(111, 96, 153, 0.6));
    text-align: center;
    /* margin-top: -220px; */
    white-space: nowrap;
    top: 62px;
    left: 903px;
}
 
  
  .waviy img {
    position: relative;
    top: 21px;
    right: 0;
    left: 0;
    margin: auto;
    display: inline-block;
    width: 46px;
    height: 90px;
    animation: waviy 1.5s infinite;
    animation-delay: calc(0.1s * var(--i));
  }
  
  @keyframes waviy {
    0%, 40%, 100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-30px);
    }
  }       

 
  .cardDeckPortrait{
    position: absolute;
    left: 44%;
    margin-top: 6%;
  }

  /* .cardDeckLandscape{
    
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 0%;
    text-align: center;

  } */
  .cardDeckLandscape {
    position: absolute;
    left: 0;
    /* right: 0; */
    /* margin-top: 0%; */
    /* text-align: center; */
    left: 48%;
    max-width: 100%;
}
  
    
  .infotext_windows {
    position: relative;
    top: 818px;
    right: 1px;
    width: 441px;
    height: 266px;
    border: 5px solid slategrey;
    /* border-image: linear-gradient(to right, #a29585, #976565, #296790, #403838); */
    border-image-slice: 1;
    border-radius: 16px;
}
.paytable {
    position: absolute;
    font-family: 'Poppins';
    color: #fffe00;
    font-size: 31px;
    /* margin-top: 131px; */
    top: 20%;
    left: 29.5%;
    Z-INDEX: 5;
}



  